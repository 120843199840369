import React from 'react';
import { createStyles, Box, Text, Title, List } from '@mantine/core';
import { Link, useParams } from 'react-router-dom';

function DisclaimerText({ children, language = 'en', ...otherProps }) {
  const { classes } = useStyles();

  if (language === 'es')
    return (
      <Box className={classes.container} {...otherProps}>
        <Title order={3}>
          DESCARGO DE RESPONSABILIDAD DE LA PRUEBA BETA DE ASSET CELLUTIONS
        </Title>
        <Text size="sm" py={10}>
          Este descargo de responsabilidad debe aparecer o ser aceptado de
          alguna manera por el usuario para que pueda participar en la
          aplicación. Tiene que ser lo más fácil posible (que aparezca en el
          teléfono al momento de acceder).
        </Text>

        <Text size="sm" py={10}>
          "Al participar en esta Prueba Beta de la aplicación Say Help de Say
          Help, LLC, usted el ‘Usuario’ acepta los siguientes términos y
          condiciones:
        </Text>

        <List
          type="ordered"
          withPadding
          size="sm"
          style={{
            listStylePosition: 'outside',
          }}>
          <List.Item py={10}>
            <Text weight={900} underline>
              ESTA PRUEBA BETA NO ES PARA EMERGENCIAS.
            </Text>
            Las emergencias deben manejarse utilizando procesos y procedimientos
            locales (por ejemplo, en los EE. UU. marque "911").
          </List.Item>

          <List.Item py={10}>
            <Text weight={900} underline>
              SUS CONTACTOS DE EMERGENCIA NECESITAN SER INFORMADOS DE QUE ESTO
              NO ES PARA EMERGENCIAS
            </Text>
            y es para un programa de pruebas BETA. No deben responder ni
            notificar al personal de emergencia si están recibiendo una alerta
            activada por parte del Usuario.
          </List.Item>

          <List.Item py={10}>
            Sus Contactos de Emergencia recibirán una encuesta de los
            desarrolladores de Say Help LLC para obtener sus comentarios y
            mejorar la aplicación. No se pedirá ni se discutirá ninguna
            información personal. Usted acepta permitir que Say Help LLC y sus
            distribuidores se pongan en contacto con sus Contactos de
            Emergencia.
          </List.Item>
          <List.Item py={10}>
            Usted acepta realizar alertas de acuerdo con lo siguiente:
            <List
              type="ordered"
              withPadding
              size="sm"
              style={{
                listStylePosition: 'outside',
                listStyleType: 'lower-latin',
              }}>
              <List.Item py={10}>
                Configure su solicitud dentro de las 72 horas posteriores a la
                recepción de la solicitud.
              </List.Item>
              <List.Item py={10}>
                Siga los procedimientos de funcionalidad de pruebas Say Help
                Beta (según lo identificado por un script de pruebas
                específico).
              </List.Item>
              <List.Item py={10}>
                Establecer al menos tres Contactos de Emergencia e informarles
                que esto es una prueba.
              </List.Item>
              <List.Item py={10}>
                Aceptar los requisitos de configuración de Say Help:
                <List
                  type="ordered"
                  withPadding
                  size="sm"
                  style={{
                    listStylePosition: 'outside',
                    listStyleType: 'lower-roman',
                  }}>
                  <List.Item py={10}>
                    Proporcionar acceso al micrófono (mientras se utiliza la
                    aplicación)
                  </List.Item>
                  <List.Item py={10}>
                    Proporcionar acceso a la cámara (mientras se utiliza la
                    aplicación)
                  </List.Item>
                  <List.Item py={10}>
                    Decir Ayuda para recopilar datos de GPS/ubicación solo
                    cuando se genera una alerta (mientras se usa la aplicación)
                    y Permitir todo el tiempo para el permiso de ubicación.
                  </List.Item>
                </List>
              </List.Item>
              <List.Item py={10}>
                Utilice el Reconocidor de voz para establecer la función de
                alerta activada por voz.
              </List.Item>
              <List.Item py={10}>
                Pruebe el proceso de alerta de la aplicación enviando alertas en
                varios entornos (casa, oficina, tienda, concierto, evento
                deportivo, etc.) y registre cualquier deficiencia y notifíquela
                al equipo de desarrollo de Say Help por correo electrónico
                (proporcionado aquí{' '}
                <a href="mailto:xxxxxx@sayhelp.com">xxxxxx@sayhelp.com</a>).
              </List.Item>
              <List.Item py={10}>
                Usted se compromete a no revelar ninguna información propiedad
                de la aplicación Say Help, incluyendo el envío de grabaciones,
                imágenes, capturas de pantalla, o discutir la prueba beta fuera
                de los contactos de emergencia y los necesarios para ayudar en
                las pruebas beta. Usted acepta no divulgar ninguna información a
                otros desarrolladores o compañías de software en el intento de
                vender o regalar información relacionada con la aplicación Say
                Help para recibir dinero o ganar influencia con dicha
                organización.
              </List.Item>
              <List.Item py={10}>
                Usted acepta no compartir información personal y proteger la
                privacidad de los empleados de Say Help, otros probadores beta y
                distribuidores.
              </List.Item>
            </List>
          </List.Item>
          <List.Item py={10}>
            Al hacer clic en el botón "Aceptar los términos", usted acepta este
            descargo de responsabilidad y el descargo de responsabilidad
            asociado con la instalación y el uso de la aplicación Say Help.
          </List.Item>
        </List>
        <Text size="sm" py={10}></Text>
      </Box>
    );

  if (language === 'en' || 1)
    return (
      <Box className={classes.container} {...otherProps}>
        <Title order={3}>ASSET CELLUTIONS BETA TEST DISCLAIMER</Title>
        <Text size="sm" py={10}>
          This disclaimer needs to pop up or somehow be agreed to by the User
          for them to participate in the app. It needs to be as easy as possible
          (pop up on their cell as they onboard?).
        </Text>

        <Text size="sm" py={10}>
          “By participating in this Beta Testing of the Say Help application
          from Say Help, LLC, you the ‘User’ agree to the following terms and
          conditions:
        </Text>

        <List
          type="ordered"
          withPadding
          size="sm"
          style={{
            listStylePosition: 'outside',
          }}>
          <List.Item py={10}>
            <Text weight={900} underline>
              THIS BETA TEST IS NOT FOR EMERGENCIES.
            </Text>
            Emergencies should be handled using local processes and procedures
            (for instance, in the USA dial “911”).
          </List.Item>

          <List.Item py={10}>
            <Text weight={900} underline>
              YOUR EMERGENCY CONTACTS NEED TO BE INFORMED THAT THIS IS NOT FOR
              EMERGENCIES
            </Text>
            and is for a BETA testing program. They should not respond or notify
            emergency personnel if they are in receipt of a triggered alert by
            the User.
          </List.Item>

          <List.Item py={10}>
            Your Emergency Contacts will receive a survey by Say Help LLC
            developers to gain their feedback to make the application better. No
            personal information will be asked or discussed. You agree to allow
            Say Help LLC and its distributors to contact your Emergency
            Contacts.
          </List.Item>
          <List.Item py={10}>
            You agree to perform alerts in accordance with the following:
            <List
              type="ordered"
              withPadding
              size="sm"
              style={{
                listStylePosition: 'outside',
                listStyleType: 'lower-latin',
              }}>
              <List.Item py={10}>
                Set up your application within 72 hours of receiving the
                application.
              </List.Item>
              <List.Item py={10}>
                Follow Say Help Beta Testing functionality procedures (as
                identified per a specific testing script).
              </List.Item>
              <List.Item py={10}>
                Establish at least three Emergency Contacts and inform them this
                is a test.
              </List.Item>
              <List.Item py={10}>
                Accept Say Help Configuration requirements:
                <List
                  type="ordered"
                  withPadding
                  size="sm"
                  style={{
                    listStylePosition: 'outside',
                    listStyleType: 'lower-roman',
                  }}>
                  <List.Item py={10}>
                    Provide access to Microphone (While Using the Application)
                  </List.Item>
                  <List.Item py={10}>
                    Provide access to Camera (While Using the Application)
                  </List.Item>
                  <List.Item py={10}>
                    Say Help to collect GPS/location data only when you raise an
                    alert (while using the application) and Allow all the time
                    for location permission.
                  </List.Item>
                </List>
              </List.Item>
              <List.Item py={10}>
                Use the Voice Recognizer to establish the voice-activated alert
                function.
              </List.Item>
              <List.Item py={10}>
                Test the application’s alert process by sending alerts in
                various environments (home, office, store, concert, sports
                event, etc.) and record any deficiencies and notify the Say Help
                development team via email (provided here{' '}
                <a href="mailto:xxxxxx@sayhelp.com">xxxxxx@sayhelp.com</a>).
              </List.Item>
              <List.Item py={10}>
                You Agree to not disclose any Say Help application proprietary
                information, including sending recordings, images, screenshots,
                or discussing the beta test outside of the emergency contacts
                and those required to assist in the beta testing. You agree not
                to disclose any information to other developers or software
                companies in the attempt to sell or give away information
                related to the Say Help app to receive monetary or gain
                influence with said organization.
              </List.Item>
              <List.Item py={10}>
                You agree to not share personal information and protect the
                privacy of Say Help employees, other beta testers, and
                distributors.
              </List.Item>
            </List>
          </List.Item>
          <List.Item py={10}>
            By clicking on the “Agree to Terms” button, you agree to this
            disclaimer and the disclaimer associated with installing and using
            the Say Help application.
          </List.Item>
        </List>
        <Text size="sm" py={10}></Text>
      </Box>
    );
}

export default DisclaimerText;

const useStyles = createStyles({
  container: {},
});
