import React from 'react';
import {
  createStyles,
  Box,
  Avatar,
  Group,
  Text,
  Paper,
  Stack,
} from '@mantine/core';

import AppTitle from './AppTitle';

import { themeColors } from '../config/themeSettings';

function AvatarProfile({
  title = '',
  subTitle = '',
  imageUrl = '',
  alt = '',
  children,
  ...otherProps
}) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      <Stack align="stretch" justify="left" spacing={0} py={20}>
        <AppTitle>Profile</AppTitle>
        <Group
          noWrap
          direction="row"
          position="left"
          spacing={0}
          className={classes.avatarContainer}>
          <Stack
            align="stretch"
            justify="center"
            spacing={0}
            className={classes.avatarPhotoContainer}>
            <Avatar
              size={80}
              src={imageUrl}
              radius={40}
              alt={alt}
              color="red"
              className={classes.avatar}
            />

            <Paper radius={8} className={classes.paper}>
              <Text align="center" className={classes.paperText}>
                + 1
              </Text>
            </Paper>
          </Stack>
          <Group
            className={classes.avatarTextContainer}
            align="left"
            px={10}
            py={10}>
            <Text align="left" className={classes.avatarTitle}>
              {title}
            </Text>
            <Text align="left" className={classes.avatarSubTitle}>
              {subTitle}
            </Text>
          </Group>
          <Box className={classes.grow}></Box>
        </Group>
      </Stack>
    </Box>
  );
}

export default AvatarProfile;

const useStyles = createStyles({
  container: {},
  link: {
    width: 100,
  },
  grow: {},
  avatarContainer: {
    width: '100%',
    height: 80,
  },
  avatarPhotoContainer: {
    position: 'relative',
  },
  avatarTextContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: 0,
  },

  avatar: {
    width: 80,
    height: 80,
    cursor: 'pointer',
  },

  avatarTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    color: themeColors.white,
  },
  avatarSubTitle: {
    fontSize: 11,
    color: themeColors.white,
    whiteSpace: 'nowrap',
  },
  paper: {
    position: 'absolute',
    bottom: -10,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12,
  },
  paperText: {
    fontSize: 11,
    fontWeight: '600',
  },
});
