import { createStyles, Box, Text, Title, Notification } from '@mantine/core';
import { useNetwork } from '@mantine/hooks';

// Zustand Store
import useUserUi from '../store/useUserUi';

import { useOs, useViewportSize } from '@mantine/hooks';

import React, { useEffect } from 'react';

import { themeColors } from '../config/themeSettings';

import AppCollapse from '../components/AppCollapse';
import InformationProfileFull from '../components/InformationProfileFull';
import ProfileContainer from '../components/ProfileContainer';

import { useMediaQuery } from '@mantine/hooks';

import HeaderMobileMain from '../components/HeaderMobileMain';
import MapContainerMobile from '../components/MapContainerMobile';

import { useParams } from 'react-router-dom';

// // Firebase
import settings from '../config/settings';
import { initializeApp } from 'firebase/app';
import {
  getDatabase,
  query,
  ref,
  onValue,
  limitToLast,
  limitToFirst,
} from 'firebase/database';

import useUserData from '../store/useUserStore';
import VideoCaptureSection from '../components/VideoCaptureSection';
import LocationProfileSection from '../components/LocationProfileSection';
import { cleanNotifications, showNotification } from '@mantine/notifications';
import { IconAccessPointOff } from '@tabler/icons';
import logger from '../utils/logger';

const firebaseApp = initializeApp(settings.firebaseConfig);
const database = getDatabase(firebaseApp);

// useUserUi - Selectors

const expandLeftSelector = state => state.expandLeft;
const expandRightSelector = state => state.expandRight;

const handleExpandLeftSelector = state => state.handleExpandLeft;
const handleExpandRightSelector = state => state.handleExpandRight;

const handleShowNotification = () => {
  showNotification({
    message: (
      <Box>
        <Title order={4}>No Internet Connection</Title>
        <Text color="dark">
          Please connect your device to the Internet and try again.
        </Text>
      </Box>
    ),
    color: 'blue',
    icon: <IconAccessPointOff size={20} />,
    radius: 'lg',

    autoClose: false,
  });
};

function ProfileMapCameraPage() {
  const { classes } = useStyles();

  const { setInitialLocation, setLastKnownLocation } = useUserData();

  const networkStatus = useNetwork();

  // useUserUi - State

  const isExpandLeft = useUserUi(expandLeftSelector);
  const isExpandRight = useUserUi(expandRightSelector);

  const handleExpandLeft = useUserUi(handleExpandLeftSelector);
  const handleExpandRight = useUserUi(handleExpandRightSelector);

  const { sessionId } = useParams();

  // get user OS to show specific App Store Badge
  const userOs = useOs();

  const getInitialLocation = sessionId => {
    const initialLocationRef = query(
      ref(database, 'sessions/' + sessionId + '/gps_data'),
      limitToFirst(1)
    );

    return onValue(initialLocationRef, snapshot => {
      snapshot.forEach(childSnapshot => {
        if (childSnapshot.exists())
          setInitialLocation(childSnapshot.key, childSnapshot.val());
        else console.log('initialLocation: No gps_data available');
      });
    });
  };

  const getLastKnownLocation = sessionId => {
    const lastLocationRef = ref(
      database,
      'sessions/' + sessionId + '/last_known_location'
    );
    const lastLocationQuery = query(lastLocationRef, limitToLast(1));

    return onValue(lastLocationQuery, snapshot => {
      snapshot.forEach(childSnapshot => {
        if (childSnapshot.exists()) {
          setLastKnownLocation(childSnapshot.key, childSnapshot.val());
        } else
          console.log('LastKnownLocation: No last_known_location available');
      });
    });
  };

  useEffect(() => {
    const lastLocationOff = getLastKnownLocation(sessionId);
    const initialLocationOff = getInitialLocation(sessionId);

    return () => {
      // Unsubscribe from  Realtime Database Listener
      lastLocationOff();
      initialLocationOff();
    };
  }, []);

  const mediaQueryDesktop = useMediaQuery(`(min-width: 1024px)`);

  const mediaQueryMobileTablet = useMediaQuery(`(max-width: 1023px)`);

  const { height } = useViewportSize();

  useEffect(() => {
    if (!networkStatus?.online) {
      handleShowNotification();
      logger.log('No Internet Connection');
    } else {
      cleanNotifications();
    }
  }, [networkStatus?.online]);

  return (
    <div className={classes.gridContainer}>
      <div className={classes.profileContainer}>
        {mediaQueryMobileTablet && <HeaderMobileMain />}

        {mediaQueryDesktop && (
          <div
            className={classes.leftColumn}
            style={{ width: isExpandLeft ? 100 : 320 }}>
            <AppCollapse
              collapsed={isExpandLeft}
              onClick={handleExpandLeft}
              className={classes.menuToggleFull}
            />
            <ProfileContainer
              sx={{
                pointerEvents: isExpandLeft ? 'none' : 'auto',
                width: isExpandLeft ? 0 : 320,
                opacity: isExpandLeft ? 0 : 1,
                transition: 'opacity 0.3s ease',
              }}>
              <InformationProfileFull />
            </ProfileContainer>
          </div>
        )}
      </div>
      <div className={classes.mapContainer}>
        <MapContainerMobile />
      </div>

      <div className={classes.locationContainer}>
        <div
          className={classes.rightColumn}
          style={{
            width: isExpandRight
              ? mediaQueryMobileTablet
                ? '100%'
                : 100
              : mediaQueryDesktop
              ? 320
              : '100%',
          }}>
          <AppCollapse
            collapsed={!isExpandRight}
            onClick={handleExpandRight}
            className={classes.menuToggleFullRight}
          />

          <Box
            className={classes.containerDesktop}
            style={{ height: mediaQueryDesktop ? height : 'inherit' }}
            sx={{
              pointerEvents: isExpandRight ? 'none' : 'auto',
              width: isExpandRight
                ? mediaQueryMobileTablet
                  ? '100%'
                  : 0
                : mediaQueryDesktop
                ? '320px'
                : '100%',
              opacity: isExpandRight ? (mediaQueryDesktop ? 0 : 1) : 1,
              transition: 'opacity 0.3s ease',
            }}>
            <Box px={mediaQueryDesktop ? 0 : 0}>
              <VideoCaptureSection />
            </Box>
            {mediaQueryDesktop && <LocationProfileSection />}
          </Box>
        </div>
      </div>
    </div>
  );
}

export default ProfileMapCameraPage;

const useStyles = createStyles(theme => ({
  containerDesktop: {
    width: '100%',

    backgroundColor: themeColors.black,

    '@media (min-width: 1024px)': {
      paddingTop: 0,

      height: '100vh',
      overflowY: 'scroll',
      height: '-webkit-fill-available',

      '::-webkit-scrollbar': {
        display: 'none',
        WebkitAppearance: 'none',
        width: 0,
        height: 0,
      },
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',

      '@supports (-webkit-touch-callout: none)': {
        height: '-webkit-fill-available',
      },

      WebkitMaskImage:
        'linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 0px, rgb(0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%)',
    },
  },

  gridContainer: {
    display: 'grid',
    height: '100%',
    width: '100%',

    // Mobile Portrait
    '@media (max-width: 767px)': {
      gridTemplateColumns: 'auto',
      // gridTemplateRows: 'auto 1fr auto',

      gridTemplateRows:
        'minmax(min-content, auto) 1fr minmax(min-content, auto)',
      alignContent: 'space-between',
    },

    // Mobile and Table Landscape
    '@media (max-width: 1023px) and (orientation: landscape) ': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'min-content 1fr',
    },

    // Tablet
    '@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape)':
      {
        // gridTemplateColumns: '1fr minmax(min-content, auto)',
        gridTemplateRows: 'min-content auto',

        gridColumn: 'auto',
      },

    // Tablet and Portrait
    '@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait)':
      {
        // gridTemplateColumns: '1fr minmax(min-content, auto)',
        gridTemplateRows: 'min-content auto',

        gridTemplateColumns: '1fr 1fr',
      },

    // Desktop
    '@media (min-width: 1024px)': {
      gridTemplateColumns:
        'minmax(min-content, auto) 1fr minmax(min-content, auto)',
      gridTemplateRows: 'auto',
      justifyContent: 'stretch',
      backgroundColor: themeColors.backgound,
    },

    // Desktop
    '@media (min-width: 1024px)': {
      gridTemplateColumns:
        'minmax(min-content, min-content) 1fr minmax(min-content, min-content)',

      gridTemplateRows: 'auto',
      justifyContent: 'stretch',
      backgroundColor: themeColors.backgound,
    },
  },

  profileContainer: {
    '@media (max-width: 1023px) and (orientation: landscape) ': {
      gridColumn: 'span 2',
    },

    '@media (min-width: 768px) and (max-width: 1023px)': {
      gridColumn: 'span 2',
    },

    '@media (min-width: 1024px)': {},
  },
  mapContainer: {
    '@media (max-width: 767px)': {},
    '@media (min-width: 768px) and (max-width: 1023px)': {},
    '@media (min-width: 1024px)': {},
  },

  locationContainer: {
    '@media (min-width: 425px) and (max-width: 1023px) ': {
      display: 'grid',
      alignItems: 'center',
      position: 'relative',
    },

    '@media (min-width: 1024px)': {},
  },

  collapseVideoBar: {
    height: 15,
    backgroundColor: themeColors.backgound,
    width: '100%',
  },

  chevron: {
    opacity: 0.7,
    zIndex: 10,
  },
  body: {
    flex: 1,
  },

  leftColumn: {
    width: 320,
    transition: 'all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    transitionDelay: '0s',
    position: 'relative',
  },

  rightColumn: {
    width: '100%',
    '@media (min-width: 1024px) ': {
      width: 300,
      transition: 'all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
      transitionDelay: '0s',
      position: 'relative',
    },
  },

  menuToggleFull: {
    position: 'absolute',
    top: 25,
    right: 35,
    zIndex: 5,
  },

  menuToggleFullRight: {
    display: 'none',
    '@media (min-width: 1024px) ': {
      display: 'inline-block',
      position: 'absolute',
      top: 25,
      left: 35,
      zIndex: 5,
    },
  },

  notification: {
    position: 'absolute',
    top: 300,
    width: '70%',
  },
}));
