import {
  createStyles,
  Box,
  Center,
  Image,
  Loader,
  Stack,
  Button,
  Progress,
} from '@mantine/core';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';

import Video from 'twilio-video';

import shallow from 'zustand/shallow';

import { v4 as uuidv4 } from 'uuid';
import Room from './Room';
import settings from '../../config/settings';

import videoCameraSample from '../../assets/videoCameraSample.jpg';
import StreamingOffBox from '../StreamingOffBox';
import useUserData from '../../store/useUserStore';
import logger from '../../utils/logger';
import { firestore } from '../../firebase';
import { useParams } from 'react-router-dom';
import { collection, doc, orderBy, query } from 'firebase/firestore';

import {
  useFirestoreDocumentData,
  useFirestoreQueryData,
} from '@react-query-firebase/firestore';
import { useShallowEffect } from '@mantine/hooks';

const getTokenIdSelector = state => state.tokenId;
const setTokenIdSelector = state => state.setTokenId;

const setRoomOptionsSelector = state => state.setRoomOptions;

const setVideoRoomDataSelector = state => state.setVideoRoomData;

let reconnectRoomCount = 0;
let reconnectRoomLimit = 3;

const VideoChat = () => {
  axios.defaults.headers.post['Content-Type'] =
    'application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  axios.defaults.headers.post['mode'] = 'cors';

  let roomConnected = false;

  const { sessionId } = useParams();

  const [room, setRoom] = useState(null);

  const getRoomOptions = useUserData(state => state.getRoomOptions);
  const setRoomOptions = useUserData(setRoomOptionsSelector);

  const setVideoRoomData = useUserData(setVideoRoomDataSelector, shallow);

  const roomOptions = getRoomOptions();

  const getTokenId = useUserData(getTokenIdSelector);

  const getUserProfile = useUserData(state => state.getUserProfile);

  const getVideoRooms = useUserData(state => state.getVideoRooms);

  const setTokenId = useUserData(setTokenIdSelector);

  const getParticipantSessionId = useUserData(
    state => state.getParticipantSessionId
  );

  const participantSessionId = getParticipantSessionId();

  const userProfileId = getUserProfile().userProfileId;
  // const videoRooms = getVideoRooms();

  const videoRooms = useUserData(state => state.videoRooms);

  const { classes } = useStyles();
  // const { getParticipantSessionId, getUserProfile, getVideoRooms } =
  //   useUserData();

  const [isLoading, setIsLoading] = useState(false);

  const videoRoomId = videoRooms.videoRoomId;
  const videoRoomUrl = videoRooms.videoRoomData.url;
  const videoRoomName = uuidv4();

  const [username, setUsername] = useState(uuidv4());
  const [roomName, setRoomName] = useState(videoRoomId);
  const [token, setToken] = useState(null);

  // Read sessions data only if sessionId is present
  const sessionRef = sessionId
    ? doc(collection(firestore, 'sessions'), sessionId)
    : null;

  const sessionValues = useFirestoreDocumentData(
    [`sessions/${sessionId}`, { sessionId }],
    sessionRef,
    {
      subscribe: true,
    },
    {
      enabled: !!sessionId,
      onError: error => {
        console.log(error);
      },
    }
  );

  // Get Video Room Data via React Query Hook
  const sessionData = sessionValues?.data;
  const videoRoomSid = sessionValues?.data?.videoRoomSid;

  const sid = videoRoomSid;

  const participantsRef = sid
    ? query(
        collection(firestore, `videoRooms/${sid}/participants`),
        orderBy('connectedAt', 'desc')
      )
    : null;

  const participantsList = useFirestoreQueryData(
    [`videoRooms/${sid}/participants`, { sid }],
    participantsRef,
    {
      subscribe: true,
    },
    {
      enabled: !!sid,
    }
  );

  const participantsData = participantsList?.data ?? [];

  const participantsConnected = participantsData?.filter(
    participant => participant.ParticipantStatus === 'connected'
  );

  const getLocalSessionId = sessionId => {
    const participantSessionId = JSON.parse(localStorage.getItem(sessionId));
    return getLocalSessionId ? getLocalSessionId : '';
  };

  const fetchedAt = getVideoRooms().videoRoomData?.fetchedAt;

  const handleReconnect = useCallback(() => {
    console.log('Force Reconnect...');

    roomConnected = false;

    setTimeout(() => {
      if (!roomConnected) {
        handleLogout();
      }
    }, 1000);

    setTimeout(() => {
      if (!roomConnected) {
        roomConnected = true;
        handleConnect();
      }
    }, 2000);
  }, [getVideoRooms().videoRoomId]);

  const handleConnect = useCallback(
    async event => {
      setIsLoading(true);

      axios
        .post(settings.generateAccessToken, {
          identity: participantSessionId,
          // room: roomName,
          roomName: getVideoRooms().videoRoomId,
          video: {
            name: 'camera',
            width: { min: 640, ideal: 1280 },
            height: { min: 480, ideal: 720 },
          },
        })
        .then(res => {
          const currentToken = res.data;
          Video.connect(currentToken, {
            name: getVideoRooms().videoRoomId,
            ...roomOptions,
          }).then(room => {
            roomConnected = true;
            reconnectRoomCount = 0;
            setIsLoading(false);
            setRoom(room);
          });
        })
        .catch(error => {
          console.log(error);
          if (
            error?.message.includes('Permission denied') ||
            error?.message.includes('not allowed')
          ) {
            console.log(error?.message);

            // Please allow microphone access so that other partipants can hear you.
            // Allow microphone SayHelp.com to access your microphone.
            toast('Allow Microphone Access', {
              icon: <IconMicrophone size={15} stroke={1.5} />,
            });
          } else {
            console.log("Can't connect to room: ", error);
            logger.log(new Error(`Cant connect to room: ${error}`));
          }

          if (error.message.includes('Room not found')) {
            if (reconnectRoomCount < reconnectRoomLimit) {
              if (participantsConnected?.length > 0) {
                console.log('Trying to Reconnect - ', reconnectRoomCount);
                // toast('Trying to Reconnect...');
                // setTimeout(() => connectToTwilio(), 5000);
              }
            }
          }

          if (roomOptions?.audio) {
            setRoomOptions({
              audio: false,
              video: false,
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [roomOptions, getVideoRooms().videoRoomId]
  );

  const handleLogout = useCallback(() => {
    setRoom(prevRoom => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach(trackPub => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
      }
      return null;
    });
  }, []);

  const handleLogin = () => {
    handleConnect();
  };

  useShallowEffect(() => {
    handleLogin();

    return () => {
      // logout from Room when alert is closed or when component unmount
      // logout when user change roomOptions: audio: false or true

      handleLogout();
    };
  }, [roomOptions, roomName, getVideoRooms().videoRoomId]);

  useEffect(() => {
    if (room) {
      const tidyUp = event => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener('pagehide', tidyUp);
      window.addEventListener('beforeunload', tidyUp);
      return () => {
        window.removeEventListener('pagehide', tidyUp);
        window.removeEventListener('beforeunload', tidyUp);
      };
    }
  }, [room, handleLogout, getVideoRooms().videoRoomId]);

  if (isLoading)
    return (
      <Progress color="red" radius="xs" size="sm" value={100} striped animate />
    );

  let render;

  if (room) {
    render = (
      <>
        <Room
          key={getVideoRooms().videoRoomId}
          userProfileId={userProfileId}
          roomName={getVideoRooms().videoRoomId}
          // token={token}
          room={room}
          setRoom={setRoom}
          roomOptions={roomOptions}
          handleLogin={handleLogin}
          handleLogout={handleLogout}
          handleReconnect={handleReconnect}
        />
      </>
    );
  } else {
    render = (
      <>
        <StreamingOffBox />
      </>
    );
  }
  // console.log('room', room);
  return render;
};

export default VideoChat;

const useStyles = createStyles({
  container: {},
  videoCamera: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
});
