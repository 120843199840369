import {
  createStyles,
  Container,
  Title,
  Text,
  Center,
  Stack,
} from '@mantine/core';

import AppLogo from '../components/AppLogo';
import useUserData from '../store/useUserStore';

const ErrorPage = () => {
  const { classes } = useStyles();

  const { getUserError } = useUserData();

  const userError = getUserError();

  return (
    <Stack align="center" justify="center" className={classes.root}>
      <Center>
        <AppLogo size={50} />
      </Center>
      <Title className={classes.title}>{userError.status ? 'Error' : ''}</Title>
      <Text
        color="dimmed"
        size="lg"
        align="center"
        className={classes.description}>
        {userError.status ? userError.message : ''}
      </Text>
    </Stack>
  );
};

export default ErrorPage;

const useStyles = createStyles(theme => ({
  root: {
    height: '100%',
  },

  inner: {
    position: 'relative',
  },

  image: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 0,
    opacity: 0.75,
  },

  content: {
    paddingTop: 220,
    position: 'relative',
    zIndex: 1,

    [theme.fn.smallerThan('sm')]: {
      paddingTop: 120,
    },
  },

  title: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,
    color: 'white',
    [theme.fn.smallerThan('sm')]: {
      fontSize: 28,
    },
  },

  description: {
    maxWidth: 540,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));
