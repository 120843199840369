import React from 'react';
import { createStyles, Box, Tooltip } from '@mantine/core';

function AppTooltip({ children, ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Tooltip
      openDelay={50}
      transition="pop"
      transitionDuration={150}
      radius="md"
      {...otherProps}
      sx={{ fontSize: 11 }}>
      {children}
    </Tooltip>
  );
}

export default AppTooltip;

const useStyles = createStyles({
  container: {},
});
