import React from 'react';
import { ActionIcon, Box, createStyles } from '@mantine/core';

import { IconChevronLeft, IconChevronRight } from '@tabler/icons';

function AppCollapse({
  collapsed = false,
  onClick,
  className,
  children,
  ...otherProps
}) {
  const { classes } = useStyles();

  return (
    <Box className={className} {...otherProps}>
      <ActionIcon
        variant="light"
        color="dark"
        radius="xl"
        size={35}
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }}
        onClick={onClick}>
        {collapsed ? (
          <IconChevronRight color="black" size={20} stroke={3} />
        ) : (
          <IconChevronLeft color="black" size={20} stroke={3} />
        )}
      </ActionIcon>
    </Box>
  );
}

export default AppCollapse;

const useStyles = createStyles({
  container: {},
  collapse: {},
});
