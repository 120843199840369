import React from 'react';
import { createStyles, Box, Divider } from '@mantine/core';
import { themeColors } from '../config/themeSettings';

function AppDivider({ width = '100%', size = 1, children, ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      <Divider
        size={size}
        color={themeColors.divider}
        className={classes.divider}
        style={{ width: width, borderRadius: 20 }}
      />
    </Box>
  );
}

export default AppDivider;

const useStyles = createStyles({
  container: {
    width: '100%',
  },
  divider: {},
});
