export default {
  // fontFamily: '"Poppins",Poppins,Roboto,Helvetica,Arial,sans-serif',

  fontFamily: 'Poppins, sans-serif',
  headings: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
  },

  focusRing: 'never',

  // Theme is deeply merged with default theme
  colorScheme: 'light',
};
