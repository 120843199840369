import React from 'react';
import { createStyles, Box, Group } from '@mantine/core';
import AppTitle from './AppTitle';
import AppText from './AppText';
import AppDivider from './AppDivider';
import { themeColors } from '../config/themeSettings';

function ProfileLocationListItem({
  profileLocationTitle,
  profileLocationList,
  children,
  ...otherProps
}) {
  const { classes } = useStyles();

  return (
    <Box className={classes.container} {...otherProps}>
      <AppTitle>{profileLocationTitle}</AppTitle>
      {profileLocationList.map(profile => (
        <React.Fragment key={profile.profileLocationName}>
          <Group>
            <AppText>{profile.profileLocationName}</AppText>
          </Group>
          <AppText className={classes.textDescription}>
            {profile.profileLocationData}
          </AppText>
          <AppDivider pb={20} />
        </React.Fragment>
      ))}
    </Box>
  );
}

export default ProfileLocationListItem;

const useStyles = createStyles({
  container: {},
  textDescription: {
    fontSize: 11,
    paddingTop: 5,
    paddingBottom: 10,
    color: themeColors.white,
  },
});
