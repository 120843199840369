import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { mountStoreDevtool } from 'simple-zustand-devtools';

const useUserStore = create(
  subscribeWithSelector((set, get) => ({
    sessions: {
      sessionId: '',
      sessionData: {},
    },

    userProfile: {
      userProfileId: '',
      userProfileData: {},
    },

    lastKnownLocation: {
      lastKnownLocationId: '',
      lastKnownLocationData: {},
    },

    initialLocation: {
      initialLocationId: '',
      initialLocationData: {},
    },

    currentLocation: {
      currentLocationId: '',
      currentLocationData: {},
    },

    videoRooms: {
      videoRoomId: '',
      videoRoomData: {},
      status: false, // true - live streaming status for separate component
    },

    participantSessionId: '',

    markers: [],

    tokenId: null,

    error: {
      status: false,
      message: '',
    },

    roomOptions: {
      audio: false,
      video: false,
    },

    setRoomOptions: roomOptions => {
      set(state => ({
        roomOptions,
      }));
    },
    getRoomOptions: () => get().roomOptions,

    getUserProfile: () => get().userProfile,
    getUserSessions: () => get().sessions,
    getUserError: () => get().error,
    getMarkersPosition: () => get().markers,

    getLastKnownLocationData: () =>
      get().lastKnownLocation.lastKnownLocationData,

    getCurrentLocationData: () =>
      get().currentLocation.currentLocationData
        ? get().currentLocation.currentLocationData
        : get().lastKnownLocation.lastKnownLocationData,

    getInitialLocationData: () => get().initialLocation.initialLocationData,
    getVideoRooms: () => get().videoRooms,

    getParticipantSessionId: () => get().participantSessionId,

    setUserError: (status = false, message = '') => {
      set(state => ({
        error: {
          status,
          message,
        },
      }));
    },

    setUserSessions: (sessionId = '', sessionData = {}) => {
      set(state => ({
        sessions: {
          sessionId,
          sessionData,
        },
      }));
    },
    setUserProfileData: (userProfileId = '', userProfileData = {}) => {
      set(state => ({
        userProfile: {
          userProfileId,
          userProfileData,
        },
      }));
    },

    setTokenId: (tokenId = '') => {
      set(state => ({
        tokenId,
      }));
    },

    setParticipantSessionId: (participantSessionId = '') => {
      set(state => ({
        participantSessionId,
      }));
    },

    setVideoRoomData: (videoRoomId = '', videoRoomData = {}) => {
      set(state => ({
        videoRooms: {
          videoRoomId,
          videoRoomData,
        },
      }));
    },

    setVideoRoomStatus: status => {
      set(state => ({
        videoRooms: {
          ...state.videoRooms,
          status,
        },
      }));
    },

    setInitialLocation: (initialLocationId = '', initialLocationData = {}) => {
      set(state => ({
        initialLocation: {
          initialLocationId,
          initialLocationData,
        },
      }));
      // get().addMarkersPosition(initialLocationData);
    },

    setLastKnownLocation: (
      lastKnownLocationId = '',
      lastKnownLocationData = {}
    ) => {
      set(state => ({
        lastKnownLocation: {
          lastKnownLocationId,
          lastKnownLocationData,
        },
      }));
      // get().addMarkersPosition(lastKnownLocationData);
    },

    setCurrentLocationData: (
      currentLocationId = '',
      currentLocationData = {}
    ) => {
      set(state => ({
        currentLocation: {
          currentLocationId,
          currentLocationData,
        },
      }));
    },

    addMarkersPosition: position => {
      if (!position) return;
      set(state => ({
        markers: [...state.markers, position],
      }));
    },

    // ===================================================
  }))
);

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('useUserStore', useUserStore);
}

export default useUserStore;
