import React from 'react';
import { createStyles, Box } from '@mantine/core';

import { ReactComponent as SayHelpLogoDesktop } from '../assets/sayhelp-logo-desktop.svg';
import { ReactComponent as SayHelpLogoMobile } from '../assets/sayhelp-logo-mobile.svg';
import { useMediaQuery } from '@mantine/hooks';

function SayHelpLogo({ children, ...otherProps }) {
  const { classes } = useStyles();

  const mediaQueryMobile = useMediaQuery(`(max-width: 768px)`);

  return (
    <Box className={classes.container} {...otherProps} px={0} py={0}>
      {mediaQueryMobile ? (
        <SayHelpLogoMobile width={100} />
      ) : (
        <SayHelpLogoDesktop width={175} />
      )}
    </Box>
  );
}

export default SayHelpLogo;

const useStyles = createStyles({
  container: {},
});
