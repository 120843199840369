import React from 'react';
import { createStyles, Text } from '@mantine/core';
import { themeColors } from '../config/themeSettings';

function AppText({ className, children, ...other }) {
  const { classes } = useStyles();
  return (
    <Text
      className={className}
      {...other}
      sx={{ fontSize: '0.85rem', color: themeColors.white }}>
      {children}
    </Text>
  );
}

export default AppText;

const useStyles = createStyles({
  container: {},
  text: {
    fontSize: '0.85rem',
    color: themeColors.white,
  },
});
