import { createStyles, Center, Loader, Stack } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppLogo from '../components/AppLogo';

import useUserData from '../store/useUserStore';

import { v4 as uuidv4 } from 'uuid';

// Firebase
import settings from '../config/settings';
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  doc,
  onSnapshot,
  collection,
  getDoc,
  query,
  where,
  getDocs,
  orderBy,
} from 'firebase/firestore';

import ProfileMapCameraPage from './ProfileMapCameraPage';
import AlertClosedPage from './AlertClosedPage';
import ErrorPage from './ErrorPage';
import logger from '../utils/logger';
import { firestore } from '../firebase';
import { useFirestoreDocumentData } from '@react-query-firebase/firestore';
import { shallowEqual, useShallowEffect } from '@mantine/hooks';

const firebaseApp = initializeApp(settings.firebaseConfig);
const db = getFirestore(firebaseApp);

const AuthPage = () => {
  const { classes } = useStyles();
  const { sessionId } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const {
    getUserSessions,
    setUserError,
    setUserSessions,
    setUserProfileData,
    setParticipantSessionId,
    setVideoRoomData,
  } = useUserData();

  const videoRooms = useUserData(state => state.videoRooms);

  const userErrorStatus = useUserData(state => state?.error?.status);

  const userSessions = getUserSessions();

  // Read sessions data only if sessionId is present
  const sessionRef = sessionId
    ? doc(collection(firestore, 'sessions'), sessionId)
    : null;

  const sessionValues = useFirestoreDocumentData(
    [`sessions/${sessionId}`, { sessionId }],
    sessionRef,
    {
      subscribe: true,
    },
    {
      enabled: !!sessionId,
      onError: error => {
        console.log(error);
      },
    }
  );

  // Get Video Room Data via React Query Hook
  const sessionData = sessionValues?.data;
  const videoRoomSid = sessionValues?.data?.videoRoomSid;

  const videoRoomsRef = videoRoomSid
    ? query(doc(collection(firestore, 'videoRooms'), videoRoomSid))
    : null;

  // Read VideoRooms Document Hook
  // if someone Connected (participantsConnected) force refetch videoRooms data
  const videoRoomsQuery = useFirestoreDocumentData(
    [`videoRooms/${videoRoomSid}/${sessionId}`, { videoRoomSid, sessionId }],
    videoRoomsRef,
    {
      subscribe: true,
      idField: '_id',
    },
    {
      enabled: !!videoRoomsRef,
    }
  );

  useShallowEffect(() => {
    if (videoRoomsQuery?.data) {
      // if (videoRooms?.videoRoomId !== videoRoomsQuery?.data?.sid)
      setVideoRoomData(videoRoomsQuery?.data?.sid, videoRoomsQuery?.data);
    }
  }, [videoRoomsQuery?.data]);

  const onError = error => {
    console.log(error);
    setIsLoading(false);
    setUserSessions('', {});

    try {
      localStorage.removeItem(String(sessionId));
    } catch (error) {
      console.log('Error removing participiantSessionId from localStorage');
      logger.log(
        new Error('Error removing participiantSessionId from localStorage')
      );
    }
  };

  const getVideoRoomsData = sid => {
    const videoRoomsRef = collection(db, 'videoRooms');

    const videoRoomsQuery = query(
      videoRoomsRef,
      where('sessionId', '==', sid),
      orderBy('accountSid', 'asc')
    );

    console.log('Read Video Data: ');

    getDocs(videoRoomsQuery)
      .then(querySnapshot => {
        querySnapshot.forEach(docSnap => {
          if (docSnap.exists()) {
            return setVideoRoomData(docSnap.id, docSnap.data());
          } else setUserError(true, `videoRooms: ${sid} doesn't exists`);
        });
      })
      .catch(error => {
        console.log(error);
        setUserError(true, String(error));
      });
  };

  const getUserProfileData = userId => {
    if (!userId) return;
    const docRef = doc(db, 'users', userId);
    getDoc(docRef)
      .then(docSnap => {
        if (docSnap?.exists())
          return setUserProfileData(userId, docSnap?.data());
        else setUserError(true, `userId: ${userId} doesn't exists`);
      })
      .catch(error => {
        console.log(error);
        setUserError(true, String(error));
      });
  };

  const setLocalParticipantSessionId = sessionId => {
    const participantSessionId = JSON.parse(localStorage.getItem(sessionId));
    if (participantSessionId) {
      setParticipantSessionId(participantSessionId);
    } else {
      const uuid = uuidv4();
      localStorage.setItem(sessionId, JSON.stringify(uuid));
      setParticipantSessionId(String(uuid));
    }
  };

  const onSuccess = data => {
    // if firestore delay more than 10 seconds
    if (typeof data === 'undefined' || data === null) {
      console.log('No user Data available. Very Slow Internet Connection');
      logger.log(
        new Error('No user Data available. Very Slow Internet Connection')
      );
      return;
    }

    setUserSessions(sessionId, data);
    logger.setUser(sessionId, { data });

    const userProfileId = data?.userId ?? null;
    getUserProfileData(userProfileId);

    setLocalParticipantSessionId(sessionId);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    const unsub = onSnapshot(
      doc(db, 'sessions', String(sessionId)),
      doc => {
        if (doc?.exists()) return onSuccess(doc.data());
      },
      error => onError(error)
    );

    // getVideoRoomsData(String(sessionId));

    return () => {
      setIsLoading(false);
      unsub();
      console.log('Unsubscribed from Sessions');
    };
  }, []);

  const AppLoaderIndicator = (
    <Stack align="center" justify="center" className={classes.root}>
      <Center>
        <AppLogo size={50} />
      </Center>
      <Loader size="md" color="red" />
    </Stack>
  );

  if (sessionValues?.isError) return <ErrorPage />;

  if (sessionValues?.isSuccess && !sessionValues?.data)
    return <AlertClosedPage />;

  if (isLoading) return AppLoaderIndicator;

  if (userErrorStatus) return <ErrorPage />;

  return (
    <>
      {sessionValues?.isSuccess &&
      sessionValues?.data?.status === 'active' &&
      userSessions?.sessionId ? (
        <ProfileMapCameraPage />
      ) : (
        <AlertClosedPage />
      )}
    </>
  );
};

export default AuthPage;

const useStyles = createStyles(theme => ({
  root: {
    height: '100%',
  },
}));
