import React from 'react';
import {
  createStyles,
  Box,
  Image,
  Group,
  Loader,
  Stack,
  ActionIcon,
} from '@mantine/core';

import streamingOff from '../assets/streamingOff.svg';
import sayhelpLogo from '../assets/sayhelp-logo.svg';
import { themeColors } from '../config/themeSettings';
import AppLogoMobile from './AppLogoMobile';
import AppLogo from './AppLogo';

function StreamingOffBox({ children, ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      <Group position="center" className={classes.streaming}>
        <Stack align="center">
          {/* <AppLogo /> */}
          <Image src={streamingOff} width={40} height={40} />
          {/* <Loader color="red" /> */}
        </Stack>
      </Group>
    </Box>
  );
}

export default StreamingOffBox;

const useStyles = createStyles({
  container: {
    width: '100%',
    height: '100%',
  },
  streaming: {
    minWidth: '100px',
    height: '35vh',
    backgroundImage: 'radial-gradient(circle at 50% 0, #3c3c3c, #141414 84%)',
    // backgroundColor: themeColors.backgound,
    // border: '1px solid #282828',
  },
});
