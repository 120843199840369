import React, { useState } from 'react';
import {
  createStyles,
  Box,
  Modal,
  Image,
  Space,
  Text,
  Stack,
} from '@mantine/core';
import { isObjectEmpty, showField } from '../utils/formatProfile';
import ProfileSection from './ProfileSection';
import AvatarProfile from './AvatarProfile';
import InfoProfile from './InfoProfile';
import dayjs from 'dayjs';

import useUserData from '../store/useUserStore';
import AppDivider from './AppDivider';

function ProfileSectionData({ children, ...otherProps }) {
  const { classes } = useStyles();
  const [profilePictureModal, setProfilePictureModal] = useState(false);
  const { getUserProfile } = useUserData();
  const userProfile = getUserProfile().userProfileData;

  const profileListGeneralInfo = isObjectEmpty(userProfile)
    ? []
    : [
        {
          profileName: 'Birthday',
          profileDetails: dayjs(userProfile?.birthday).isValid()
            ? dayjs(userProfile?.birthday).format('DD/MM/YYYY').toString()
            : showField(userProfile?.birthday),
        },
        {
          profileName: 'Gender',
          profileDetails: showField(userProfile.gender),
        },
      ];

  const profileListUniqueIdentifiers = isObjectEmpty(userProfile)
    ? []
    : [
        { profileName: 'Scars', profileDetails: showField(userProfile.scars) },
        {
          profileName: 'Tattoo',
          profileDetails: showField(userProfile.tattoos),
        },
      ];
  const profileListMedicalInfo = isObjectEmpty(userProfile.medical)
    ? []
    : [
        {
          profileName: 'Medical Conditions',
          profileDetails: showField(userProfile.medical.medicalConditions),
        },
        {
          profileName: 'Medical Notes',
          profileDetails: showField(userProfile.medical.medicalNotes),
        },
        {
          profileName: 'Medications',
          profileDetails: showField(userProfile.medical.medications),
        },
      ];

  const profileListContactInfo = isObjectEmpty(userProfile)
    ? []
    : [
        { profileName: 'Mobile', profileDetails: showField(userProfile.phone) },
        { profileName: 'Email', profileDetails: showField(userProfile.email) },
      ];

  const profileListEmergencyContacts = userProfile.emergencyContacts
    ? userProfile.emergencyContacts.map(value => [
        { profileName: 'Name', profileDetails: showField(value.name) },
        {
          profileName: 'Phone',
          profileDetails:
            showField(value.callingCode) + '' + showField(value.phone),
        },
        {
          profileName: 'Relationship',
          profileDetails: showField(value.relationship),
        },
      ])
    : [];

  const profileListAddressInfo = isObjectEmpty(userProfile.address)
    ? []
    : [
        {
          profileName: 'Address Line 1',
          profileDetails: showField(userProfile.address.line1),
        },
        {
          profileName: 'Address Line 2',
          profileDetails: showField(userProfile.address.line2),
        },
        {
          profileName: 'City',
          profileDetails: showField(userProfile.address.city),
        },
        {
          profileName: 'State',
          profileDetails: showField(userProfile.address.state),
        },
        {
          profileName: 'Country',
          profileDetails: showField(userProfile.address.country),
        },
        {
          profileName: 'ZIP',
          profileDetails: showField(userProfile.address.zip),
        },
      ];

  const profileListPersonalCarInfo = isObjectEmpty(userProfile.car)
    ? []
    : [
        {
          profileName: 'Brand',
          profileDetails: showField(userProfile.car.make),
        },
        {
          profileName: 'Model',
          profileDetails: showField(userProfile.car.model),
        },
        {
          profileName: 'Color',
          profileDetails: showField(userProfile.car.color),
        },
        {
          profileName: 'Reg. number',
          profileDetails: showField(userProfile.car.plateNumber),
        },
      ];

  let diffInYears = '';

  if (dayjs(userProfile?.birthday, 'M/D/YYYY', true).isValid()) {
    diffInYears = dayjs(dayjs().format('M/D/YYYY')).diff(
      userProfile.birthday,
      'years'
    );
  }

  const avatarTitle = showField(userProfile.name);
  const avatarYears = diffInYears ? `${diffInYears} Years old, ` : '';

  const avatarSubTitle = avatarYears + showField(userProfile.gender);

  const avatarImageUrl = userProfile?.profileImage
    ? userProfile.profileImage
    : '';

  return (
    <Box className={classes.container} {...otherProps}>
      <Modal
        opened={profilePictureModal}
        closeOnClickOutside={true}
        closeOnEscape={true}
        onClose={() => setProfilePictureModal(false)}
        title={`${avatarTitle}, ${avatarSubTitle},`}
        centered>
        <Stack align="center" spacing={15}>
          <Image
            src={avatarImageUrl}
            withPlaceholder
            placeholder={<Text align="center">No Photo</Text>}
          />
        </Stack>
      </Modal>

      <ProfileSection>
        <AvatarProfile
          title={avatarTitle}
          subTitle={avatarSubTitle}
          imageUrl={avatarImageUrl}
          alt={avatarTitle}
          onClick={() => setProfilePictureModal(true)}
        />
        <Box>
          <InfoProfile
            profileTitle="General Info"
            profileList={profileListGeneralInfo}
          />
          <InfoProfile
            profileTitle="Unique Identifiers"
            profileList={profileListUniqueIdentifiers}
          />

          <InfoProfile
            profileTitle="Medical Info"
            profileList={profileListMedicalInfo}
          />

          <InfoProfile
            profileTitle="Contact Info"
            profileList={profileListContactInfo}
          />
          {profileListEmergencyContacts.map((contact, index) => (
            <InfoProfile
              key={`${contact}${index + 1}`}
              profileTitle={`Emergency Contact ${index + 1}`}
              profileList={contact}
            />
          ))}

          <InfoProfile
            profileTitle="Address Info"
            profileList={profileListAddressInfo}
          />
          <InfoProfile
            profileTitle="Personal Car Info"
            profileList={profileListPersonalCarInfo}
          />
          <Space h={10} w={10} />
          <AppDivider />
          <Space h={70} w={10} />
        </Box>
      </ProfileSection>
    </Box>
  );
}

export default ProfileSectionData;

const useStyles = createStyles({
  container: {},
  scrollArea: {},
});
