import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Center,
  Group,
  Image,
  Loader,
  Notification,
  Paper,
  Progress,
  Stack,
  Text,
} from '@mantine/core';

import React, { useState, useEffect, useCallback } from 'react';
import Video from 'twilio-video';
import AppText from '../AppText';
import StreamingOffBox from '../StreamingOffBox';
import Participant from './Participant';

import { createStyles, Box } from '@mantine/core';

import { IoMdHeadset } from 'react-icons/io';

import { ReactComponent as LiveRedDot } from '../../assets/live-red-dot.svg';
import videoPoster from '../../assets/videoPoster.png';
import logger from '../../utils/logger';
import { IconMicrophone, IconMicrophoneOff, IconX } from '@tabler/icons';

import useUserData from '../../store/useUserStore';
import { MdAccountCircle, MdOutlineAccountCircle } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import StreamingOffBoxLoader from '../StreamingOffBoxLoader';
import { collection, orderBy, query } from 'firebase/firestore';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { firestore } from '../../firebase';
import { useShallowEffect } from '@mantine/hooks';

let reconnectRoomCount = 0;
let reconnectRoomLimit = 3;

let roomConnected = false;

const Room = ({
  userProfileId = '',
  roomName,
  room,
  setRoom,
  roomOptions,
  handleLogout,
  handleLogin,
  handleReconnect,
}) => {
  const { setRoomOptions, getUserProfile } = useUserData();

  const userProfile = getUserProfile().userProfileData;

  const [participants, setParticipants] = useState([]);

  const { classes } = useStyles();

  const getVideoRooms = useUserData(state => state.getVideoRooms);

  const [isConnecting, setIsConnecting] = useState(false);

  const avatarImageUrl = userProfile.profileImage
    ? userProfile.profileImage
    : '';

  const [userProfileParticipant, setUserProfileParticipant] = useState(<></>);

  // Listen to the "beforeunload" event on window to leave the Room
  // when the tab/browser is being closed.
  // window.addEventListener('beforeunload', () => handleLogout());

  const sid = roomName;

  const participantsRef = sid
    ? query(
        collection(firestore, `videoRooms/${sid}/participants`),
        orderBy('connectedAt', 'desc')
      )
    : null;

  const participantsList = useFirestoreQueryData(
    [`videoRooms/${sid}/participants`, { sid }],
    participantsRef,
    {
      subscribe: true,
      includeMetadataChanges: true,
    },
    {
      enabled: !!sid,
    }
  );

  const participantsData = participantsList?.data ?? [];

  const participantsConnected = participantsData?.filter(
    participant => participant.ParticipantStatus === 'connected'
  );

  const participantsDisconnected = participantsData?.filter(
    participant => participant.ParticipantStatus === 'disconnected'
  );

  function reconnectToTwilio(room, error) {
    // console.log('reconnectToTwilio Error', error);
    // console.log('reconnectToTwilio Room', room);
    if (error) {
      console.log('Room status: ', error.message);
      if (error?.message !== 'Room completed')
        logger.log(`Room status: ${error.message}`);
    }
  }

  const fetchedAt = getVideoRooms().videoRoomData?.fetchedAt;

  useEffect(() => {
    const participantConnected = participant => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };

    const participantDisconnected = participant => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );
    };

    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);

    room.participants.forEach(participantConnected);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room]);

  // Filter Participiant and show only ONE stream from userProfileId

  useEffect(() => {
    participants.map(participant => {
      if (
        participant.identity === userProfileId &&
        participant.state === 'disconnected'
      ) {
        // force reconnect with delay
        setTimeout(() => {
          if (room) {
            handleReconnect();
            console.log('force reconnect with delay');
          }
        }, 1000);
      }
    });
  }, [fetchedAt]);

  let remoteParticipants = <></>;
  let otherParticipants = <></>;

  if (userProfileId) {
    const res = participants
      ? participants?.find(
          participant => participant.identity === userProfileId
        )
      : {};

    const resOthers = participants?.filter(
      participant => participant.identity !== userProfileId
    );

    // Find how many dispatcher are connected, by filter using Identity
    const dispatchers = participantsConnected?.filter(participant =>
      participant?.ParticipantIdentity?.includes('dispatcher')
    );

    if (typeof res !== 'undefined') {
      if (res?.state === 'connected') {
        remoteParticipants = (
          <Participant
            key={res?.sid}
            participant={res}
            participantCount={
              Array.isArray(participants) ? participants?.length : 0
            }
            dispatchersCount={
              Array.isArray(dispatchers) ? dispatchers?.length : 0
            }
          />
        );
      }

      if (typeof resOthers !== 'undefined') {
        otherParticipants = participants.map((participant, index) => {
          // if (!participant?.audioTracks?.size && !participant?.videoTracks?.size)
          //   return;
          if (index < 2)
            return participant.identity.includes('dispatcher') ? (
              <Avatar
                size={30}
                radius={20}
                color="white"
                key={participant?.sid + index}>
                <IoMdHeadset size={22} color="#C8453A" />
              </Avatar>
            ) : (
              <Avatar
                size={30}
                radius={20}
                color="gray"
                key={participant?.sid + index}>
                <MdOutlineAccountCircle size={30} />
              </Avatar>
            );
        });
      }
    }
  }

  if (room)
    return (
      <div className={classes.container}>
        {Array.isArray(participants) && participants?.length > 0 ? (
          <Box className={classes.otherParticipants}>
            <Avatar.Group spacing="sm">
              <Avatar
                px={0}
                py={0}
                size={30}
                radius={20}
                color="red"
                src={avatarImageUrl}
                className={classes.avatarAnimation}
              />
              {otherParticipants}
              {otherParticipants?.length > 2 && (
                <Avatar
                  size={30}
                  radius={20}
                  className={classes.avatarAnimation}>
                  <Text
                    weight={500}
                    color="gray"
                    size={12}
                    style={{ whiteSpace: 'nowrap' }}>
                    +{participants?.length - 2}
                  </Text>
                </Avatar>
              )}
            </Avatar.Group>
          </Box>
        ) : (
          <>
            <Box>
              <Progress
                radius="xs"
                size="sm"
                value={100}
                striped
                animate
                style={{ position: 'absolute', bottom: 0 }}
              />
            </Box>
          </>
        )}
        <div className="remote-participants">{remoteParticipants}</div>
      </div>
    );

  return (
    <div className={classes.containerOffBox}>
      <Box>
        <StreamingOffBox />
      </Box>
    </div>
  );
};

export default Room;

const useStyles = createStyles({
  container: {
    position: 'relative',
    backgroundImage: 'radial-gradient(circle at 50% 0, #3c3c3c, #141414 84%)',
    '@media (min-width: 1024px) ': {
      minHeight: 200,
    },
  },
  containerOffBox: {
    position: 'relative',
    // height: 0,
    backgroundImage: 'radial-gradient(circle at 50% 0, #3c3c3c, #141414 84%)',
    '@media (max-width: 767px) and (orientation: portrait)': {
      height: 0,
    },
  },

  position: {
    position: 'absolute',
    top: 5,
    width: '100%',
  },

  status: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  redDot: {
    height: 5,
    width: 5,
    backgroundColor: '#ed1d23',
    borderRadius: '50%',
    display: 'inline-block',
    margin: 7,
  },
  greyDot: {
    height: 5,
    width: 5,
    backgroundColor: '#ccc',
    borderRadius: '50%',
    display: 'inline-block',
    margin: 7,
  },

  mic: {
    position: 'absolute',
    bottom: 10,
    right: 30,
  },

  notification: {
    position: 'absolute',
    top: 30,
    width: '100%',
    zIndex: 105,
  },

  otherParticipants: {
    opacity: 0.4,
    WebkitTransition: '250ms',

    '&:hover': {
      opacity: 1,
      WebkitTransition: '250ms',
      boxShadow: '0px 7px 20px 0px rgba(0, 0, 0, 0.07)',
    },

    position: 'absolute',
    zIndex: 200,
    // top: '0%',
    // left: '0%',
    // bottom: 0,
    // right: 0,
    // width: '100%',
    // height: '100%',

    // paddingTop: 10,
    // paddingRight: 20,

    left: 20,
    bottom: 25,

    '@media (min-width: 1024px)': {
      left: 20,
      bottom: 30,
      // height: 190,
    },

    // overflowY: 'scroll',
    // height: 300,

    // opacity: 1,

    // '::-webkit-scrollbar': {
    //   display: 'none',
    //   WebkitAppearance: 'none',
    //   width: 0,
    //   height: 0,
    // },
    // msOverflowStyle: 'none',
    // scrollbarWidth: 'none',

    // WebkitMaskImage:
    //   'linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 0px, rgb(0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%)',

    display: [
      '-webkit-box',
      '-webkit-flex',
      '-moz-box',
      '-moz-flex',
      '-ms-flexbox',
      'flex',
    ],
    WebkitBoxPack: 'center',
    msFlexPack: 'center',
    WebkitJustifyContent: 'center',
    MozJustifyContent: 'center',
    justifyContent: 'flex-end',
    WebkitBoxAlign: 'center',
    msFlexAlign: 'center',
    WebkitAlignItems: 'center',
    MozAlignItems: 'center',
    alignItems: 'flex-end',
  },

  avatar: {
    width: 20,
    height: 20,
  },

  avatarAnimation: {
    // opacity: 1,
    // WebkitTransition: '350ms',
    boxShadow: '0px 7px 20px 0px rgba(0, 0, 0, 0.5)',
    // outline: '5px solid grey',
  },

  notificationMessage: {
    fontSize: 11,
    backgroundColor: 'black',
  },
});
