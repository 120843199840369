import React from 'react';
import { createStyles, Transition } from '@mantine/core';

function AppAnimation({
  mounted = true,
  transition = 'fade',
  duration = 300,
  timingFunction = 'ease',
  children,
  ...otherProps
}) {
  const { classes } = useStyles();
  return (
    <Transition
      mounted={mounted}
      transition={transition}
      duration={duration}
      timingFunction={timingFunction}
      {...otherProps}>
      {styles => <div style={styles}>{children}</div>}
    </Transition>
  );
}

export default AppAnimation;

const useStyles = createStyles({
  container: {},
});
