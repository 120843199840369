import React, { useEffect, useState } from 'react';
import { createStyles, Box, CloseButton } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

import { themeColors } from '../config/themeSettings';

import AppAnimation from './AppAnimation';
import LocationProfileSection from './LocationProfileSection';

function LocationProfileMobile({ onClose, children, ...otherProps }) {
  const [mounted, setMounted] = useState(false);
  const { classes } = useStyles();

  const duration = 400;

  useEffect(() => {
    setMounted(true);
  }, []);

  const { height } = useViewportSize();

  return (
    <Box className={classes.root} style={{ height }} {...otherProps}>
      <Box className={classes.container} style={{ height }}>
        <AppAnimation
          transition="pop-top-left"
          mounted={mounted}
          duration={duration * 2}>
          <Box pl="1.2rem">
            <CloseButton
              title="Close"
              variant="transparent"
              iconSize={30}
              onClick={onClose}
              py={30}
              style={{ color: 'white' }}
            />
          </Box>
          <LocationProfileSection />
        </AppAnimation>
      </Box>
    </Box>
  );
}

export default LocationProfileMobile;

const useStyles = createStyles({
  root: {
    height: '100vh',
    height: '-webkit-fill-available',
    backgroundColor: themeColors.backgound,
  },

  container: {
    height: '100vh',
    overflowY: 'scroll',
    height: '-webkit-fill-available',
    backgroundColor: themeColors.backgound,

    '::-webkit-scrollbar': {
      display: 'none',
      WebkitAppearance: 'none',
      width: 0,
      height: 0,
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',

    '@supports (-webkit-touch-callout: none)': {
      height: '-webkit-fill-available',
    },

    WebkitMaskImage:
      'linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 15px, rgb(0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%)',

    backgroundColor: themeColors.backgound,
  },
  scrollArea: {
    height: '100vh',
    overflowY: 'scroll',
    height: '-webkit-fill-available',
    '@supports (-webkit-touch-callout: none)': {
      height: '-webkit-fill-available',
    },
    opacity: 1,
    WebkitMaskImage:
      'linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 10px, rgb(0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%)',

    backgroundColor: themeColors.backgound,
  },
  logoNavigation: {
    display: 'flex',
    justifyContent: 'space-between',

    paddingTop: 20,
    paddingBottom: 25,
  },

  closeButton: {},
  backLink: {},
  logo: {},
  rightSide: {
    visibility: 'hidden',
  },
});
