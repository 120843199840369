import React from 'react';
import { Box, createStyles } from '@mantine/core';
import { themeColors } from '../config/themeSettings';
import { useViewportSize } from '@mantine/hooks';

function ProfileContainer({ children, ...otherProps }) {
  const { classes } = useStyles();

  const { height } = useViewportSize();

  return (
    <Box className={classes.root} {...otherProps} style={{ height }}>
      <Box className={classes.container} style={{ height }}>
        <div className={classes.profileContainer}>{children}</div>
      </Box>
    </Box>
  );
}

export default ProfileContainer;

const useStyles = createStyles({
  root: {
    height: '100vh',
    height: '-webkit-fill-available',
    backgroundColor: themeColors.background,
  },

  container: {
    height: '100vh',
    overflowY: 'scroll',
    height: '-webkit-fill-available',
    backgroundColor: themeColors.background,

    '::-webkit-scrollbar': {
      display: 'none',
      WebkitAppearance: 'none',
      width: 0,
      height: 0,
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',

    '@supports (-webkit-touch-callout: none)': {
      height: '-webkit-fill-available',
    },

    WebkitMaskImage:
      'linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 15px, rgb(0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%)',
  },

  scrollArea: {
    height: '100vh',
    overflowY: 'scroll',
    opacity: 1,
    WebkitMaskImage:
      'linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 25px, rgb(0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%)',

    '@supports (-webkit-touch-callout: none)': {
      height: '-webkit-fill-available',
    },
  },

  profileContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 0,
    flexDirection: 'row',
  },
});
