import React, { useState } from 'react';
import {
  createStyles,
  Box,
  Menu,
  ActionIcon,
  Group,
  Text,
  UnstyledButton,
} from '@mantine/core';
import {
  IconChevronUp,
  IconCookie,
  IconSettings,
  IconWorld,
} from '@tabler/icons';

function CookieButton({ children, ...otherProps }) {
  const { classes } = useStyles();

  const [countryOpened, setCountryOpened] = useState(false);

  const changeLanguage = lng => {
    // i18n.changeLanguage(lng);
    setCountryOpened(false);
  };

  return (
    <Box className={classes.container} {...otherProps}>
      <UnstyledButton onClick={() => setCountryOpened(s => !s)}>
        <Group className={classes.languageSelector} spacing={10} noWrap>
          <IconSettings color="#2A3135" stroke={2} size={18} />
          <Text
            weight={500}
            color="dark"
            size="sm"
            sx={{ whiteSpace: 'nowrap' }}>
            Privacy Settings
          </Text>
          <IconChevronUp
            size={12}
            stroke={3}
            style={{
              transform: countryOpened ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          />
        </Group>
      </UnstyledButton>
    </Box>
  );
}

export default CookieButton;

const useStyles = createStyles({
  container: {},
  languageSelector: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 7,
    paddingBottom: 7,
    borderRadius: 20,
    margin: 10,

    border: '1px solid #141414',
    '&:hover': {
      cursor: 'pointer',
      // backgroundColor: 'rgba(0, 0, 0, 0.02)',

      backgroundColor: '#F6F6F8',
      // outline: '2px solid #F5F5FA',

      WebkitTransition: '150ms',
    },

    '&:active': {
      // backgroundColor: '#FDFDFD',
      // outline: '2px solid #F5F5FA',
      cursor: 'pointer',
      transform: 'translateY(1.5px)',
      WebkitTransition: '150ms',
    },
  },
});
