export const themeSizes = {
  maxWidth: 1600,
  minWidth: 375,
  paddingHorizontal: 20,
  paddingHorizontalMobile: 20,
  paddingVertical: 20,
  paddingInside: 20,
  paddingLocationProfile: 20,
  paddingMini: 120,
  paddingFull: 360,
};

export const themeBreakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1400,
  xxl: 1536,
};

export const themeColors = {
  backgound: '#141414',
  black: '#141414',
  white: '#fff',
  divider: '#323232',
  darkGrey: '#646464',
  border: '#282828',
  link: '#3e67ed',
  anchor: '#1E1E1E',
  text: '#1E1E1E',
  red: '#ec1c24',
  menu: '#1E1E1E',

  lightGreen: '#42D490',
  darkGreen: '#32612E',
};
