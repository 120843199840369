import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { mountStoreDevtool } from 'simple-zustand-devtools';

const useUserUi = create(
  subscribeWithSelector((set, get) => ({
    expandLeft: false,
    expandRight: false,
    toggleAudioVideo: true,
    toggleMute: true,

    // 'front' - default camera, 'rear' - back camera, 'none' - no camera or default
    toggleCameraSwitch: 'front',

    // Streaming format by default Twilio: 'video' or 'audio' or 'none'

    streamingFormat: 'video',

    getUserUi: () => get(),

    handleExpandLeft: () => {
      set(state => ({
        expandLeft: !state.expandLeft,
      }));
    },

    handleExpandRight: () => {
      set(state => ({
        expandRight: !state.expandRight,
      }));
    },

    // Features

    // Switch to Rear or Front Camera from streaming video
    handleToggleCameraSwitch: (toggleCameraSwitch = 'front') => {
      set(state => ({
        toggleCameraSwitch,
      }));
    },

    // Mute or Un Mute your own mic or audio hearing from streaming video
    handleToggleMute: () => {
      set(state => ({
        toggleMute: !state.toggleMute,
      }));
    },

    streamMute: () => {
      set(state => ({
        toggleMute: true,
      }));
    },

    streamUnmute: () => {
      set(state => ({
        toggleMute: false,
      }));
    },

    // Collapsable Expand or Minimize
    handleToggleAudioVideo: () => {
      set(state => ({
        toggleAudioVideo: !state.toggleAudioVideo,
      }));
    },

    // Streaming format for Twilio set by routes

    setStreamingFormat: (streamingFormat = 'video') => {
      set(state => ({
        streamingFormat: streamingFormat,
      }));
    },
  }))
);

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('useUserUi', useUserUi);
}

export default useUserUi;
