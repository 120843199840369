import dayjs from 'dayjs';

export const showField = (field = '', subString = 0) => {
  if (typeof field === 'undefined' || field === null) return 'None';
  if (field === '') return 'None';
  if (subString) return String(field).slice(0, subString);
  return String(field);
};

export const isObjectEmpty = obj => {
  if (typeof obj !== 'object') return true;

  return Object.values(obj).every(o => o === null || o === '');
};

export const convertTimestampToString = timestamp => {
  if (!timestamp) return;

  const timestampToDate = new Date(Number(timestamp));
  const dateToString =
    timestampToDate.getMonth() +
    1 +
    '/' +
    timestampToDate.getDate() +
    '/' +
    timestampToDate.getFullYear() +
    ' ' +
    timestampToDate.getHours() +
    ':' +
    timestampToDate.getMinutes() +
    ':' +
    timestampToDate.getSeconds();

  return dateToString;
};

export const processSpeed = speed => {
  let mphSpeed, mphSpeedText;
  if (speed) {
    mphSpeed = speed / 0.44704;
    mphSpeedText = Number.parseFloat(mphSpeed).toFixed(1).toString() + ' mph';
    if (speed < 0) mphSpeedText = mphSpeedText + ' (turns back)';
  }
  const mphSpeedString = mphSpeedText;

  return mphSpeedString;
};

export const processSpeedMph = speed => {
  let mphSpeed, mphSpeedText;
  if (speed) {
    mphSpeed = speed / 0.44704;
    if (speed < 0) mphSpeed = (speed / 0.44704) * -1;
    mphSpeedText = Number.parseFloat(mphSpeed);
  }
  const mphSpeedString = mphSpeedText;

  return mphSpeedString;
};

export const getCourseFromDegrees = course => {
  if (typeof course === 'undefined') return 'None';
  let directions = [
    'North (N)',
    'North North East (NNE)',
    'North East (NE)',
    'East North East (ENE)',
    'East (E)',
    'East South East (ESE)',
    'South East (SE)',
    'South South East (SSE)',
    'South (S)',
    'South South West (SSW)',
    'South West (SW)',
    'West South West (WSW)',
    'West (W)',
    'West North West (WNW)',
    'North West (NW)',
    'North North West (NNW)',
  ];
  let directionIndex = Math.round((course - 11.25) / 22.5);
  if (directionIndex < 0) {
    directionIndex = 0;
  }
  return directions[directionIndex];
};

export const latitudeToMetter = (lat1, lon1, lat2, lon2) => {
  // generally used geo measurement function
  let R = 6378.137; // Radius of earth in KM
  var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
  var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d * 1000; // meters
};

export const latitudeMetter = (lat1, lon1) => {
  // generally used geo measurement function
  let R = 6378.137; // Radius of earth in KM
  var dLat = (lat1 * Math.PI) / 180;
  var dLon = (lon1 * Math.PI) / 180;
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d * 1000; // meters
};

//    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)
//    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)
//    unit = the unit you desire for results
//           where: 'M' is statute miles (default)
//                  'K' is kilometers
//                  'N' is nautical miles

export const gpsDistance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

export const convertLatLng = position => {
  const zeroCenter = {
    lat: 0,
    lng: 0,
  };
  if (!position) return zeroCenter;
  if (!position?.latitude) return zeroCenter;
  if (!position?.longitude) return zeroCenter;

  if (position?.latitude === 'NaN') return zeroCenter;
  if (position?.longitude === 'NaN') return zeroCenter;

  return {
    lat: parseFloat(position?.latitude),
    lng: parseFloat(position?.longitude),
  };
};

export const getTimeFromTimestamp = timestamp => {
  if (typeof timestamp === 'undefined' || timestamp === null) return 'None';
  return dayjs(parseInt(timestamp)).format('YYYY-MM-DD hh:mm:ss A');
};
