import React from 'react';
import { createStyles, Title } from '@mantine/core';
import { themeColors, themeSizes } from '../config/themeSettings';

function AppTitle({ order = 3, children, ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Title
      align="left"
      order={order}
      className={classes.container}
      {...otherProps}>
      {children}
    </Title>
  );
}

export default AppTitle;

const useStyles = createStyles({
  container: {
    // fontSize: 18,
    fontSize: '1.12rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: themeColors.white,
    width: '100%',
    paddingTop: themeSizes.paddingInside,
    paddingBottom: themeSizes.paddingInside,
  },
});
