import React from 'react';
import { createStyles, Box, Badge, Text } from '@mantine/core';

function ComingSoonText({ children, ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      <Badge
        px={10}
        py={10}
        radius="xl"
        size="lg"
        style={{ backgroundColor: '#DA3831' }}>
        <Text size="xs" color="white" align="center" weight={400}>
          Coming Soon ...
        </Text>
      </Badge>
    </Box>
  );
}

export default ComingSoonText;

const useStyles = createStyles({
  container: {},
});
