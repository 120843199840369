import React from 'react';
import { Box, createStyles, Image } from '@mantine/core';

import SayhelpStaySafeLogoBlack from '../assets/sayhelp-staysafe-logo-black.svg';

import { themeColors } from '../config/themeSettings';

function AppLogoMobile({ size, children, ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      <Box className={classes.logoContainer}>
        <Box px={5} className={classes.logo}>
          <Image
            // width={size}
            // height={size}
            src={SayhelpStaySafeLogoBlack}
            alt="Say Help Stay Safe"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AppLogoMobile;

const useStyles = createStyles({
  container: {},
  logoContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  logoTextContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  logo: {
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  logoTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: themeColors.white,
    whiteSpace: 'nowrap',
  },
  logoSubTitle: {
    fontSize: 7,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 2.2,

    color: themeColors.white,

    marginTop: 0,
  },
});
