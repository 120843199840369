import React from 'react';
import { Box, createStyles, Image } from '@mantine/core';

import SayhelpStaySafeLogoBlack from '../assets/sayhelp-staysafe-logo-black.svg';

import { themeColors } from '../config/themeSettings';

function AppLogo({ size, children, ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      <Image
        // width={size}
        // height={size}
        src={SayhelpStaySafeLogoBlack}
        alt="Say Help Stay Safe"
      />
    </Box>
  );
}

export default AppLogo;

const useStyles = createStyles({
  container: {
    width: 145,
    height: 50,
  },
  logoContainer: {
    width: 145,
    height: 50,
    gap: 10,
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
  logoTextContainer: {
    flexDirection: 'column',
    justifyContent: 'center',

    gap: 0,
  },

  logo: {
    width: 140,
    height: 50,
  },

  logoTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: themeColors.white,
    whiteSpace: 'nowrap',
  },
  logoSubTitle: {
    fontSize: 9,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 2.8,

    color: themeColors.white,
  },
});
