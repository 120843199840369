import React from 'react';
import { createStyles, Box, Table, Stack } from '@mantine/core';
import AppTitle from './AppTitle';
import { themeColors, themeSizes } from '../config/themeSettings';

function InfoProfile({
  profileTitle = '',
  profileList = [],
  children,
  ...otherProps
}) {
  const { classes } = useStyles();

  const rows = profileList.map((element, index) => (
    <tr key={`${element.profileName}${index}`}>
      <td className={classes.profileName}>{element.profileName}</td>
      <td
        className={classes.profileDetails}
        sx={{ wordWrap: 'break-word !important' }}>
        {element.profileDetails}
      </td>
    </tr>
  ));

  if (profileList.length === 0) return <></>;

  return (
    <Box className={classes.container} {...otherProps}>
      <Stack align="stretch" justify="left" spacing={0}>
        <AppTitle>{profileTitle}</AppTitle>

        <Table className={classes.profileContainer} verticalSpacing={5}>
          <tbody>{rows}</tbody>
        </Table>
      </Stack>
    </Box>
  );
}

export default InfoProfile;

const useStyles = createStyles({
  container: {
    paddingTop: themeSizes.paddingInside / 2,
  },
  profileContainer: {
    color: themeColors.white,
    fontSize: 11,
  },
  profileName: {
    width: '40% !important',
    fontSize: 11,
    color: themeColors.darkGrey,

    borderBottomWidth: '0 !important',

    paddingTop: '5 !important',
    paddingBottom: '5 !important',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',

    whiteSpace: 'nowrap',
    verticalAlign: 'top',
  },
  profileDetails: {
    fontSize: 11,
    color: themeColors.white,
    borderBottomWidth: '0 !important',
    paddingTop: '5 !important',
    paddingBottom: '5 !important',
    paddingLeft: '5 !important',
    paddingRight: '0 !important',
    // whiteSpace: 'normal',
    // whiteSpace: 'nowrap',
    wordWrap: 'break-word !important',
    maxWidth: 200,
    verticalAlign: 'top',
  },
});
