import React from 'react';
import { Box, createStyles, Group } from '@mantine/core';
import AppLogo from './AppLogo';
import { themeSizes } from '../config/themeSettings';

function LogoContainer({ children, ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      <Group
        noWrap
        direction="row"
        position="apart"
        spacing={0}
        className={classes.appLogoContainer}>
        <AppLogo />
      </Group>
    </Box>
  );
}

export default LogoContainer;

const useStyles = createStyles({
  container: {
    paddingTop: themeSizes.paddingInside,
    paddingBottom: themeSizes.paddingInside,
  },
  appLogoContainer: {},
});
