import React from 'react';
import {
  createStyles,
  Box,
  Notification,
  Text,
  Group,
  Paper,
  Stack,
  Badge,
  Overlay,
  Loader,
} from '@mantine/core';
import { themeColors } from '../config/themeSettings';

import VideoChat from './twilio/VideoChat';

import useUserData from '../store/useUserStore';
import { useParams } from 'react-router-dom';
import { collection, doc, getFirestore, query } from 'firebase/firestore';
import {
  useFirestoreDocumentData,
  useFirestoreQueryData,
} from '@react-query-firebase/firestore';
import { initializeApp } from 'firebase/app';
import settings from '../config/settings';
import StreamingOffBox from './StreamingOffBox';

import { toast } from 'react-hot-toast';
import { IconCheck, IconPoint, IconVideo } from '@tabler/icons';
import { firestore } from '../firebase';
import { useEffect } from 'react';

function VideoCaptureSection({ children, ...otherProps }) {
  const { classes } = useStyles();
  const { sessionId } = useParams();

  const { setVideoRoomData, getVideoRooms } = useUserData();

  const getUserProfile = useUserData(state => state.getUserProfile);
  const getParticipantSessionId = useUserData(
    state => state.getParticipantSessionId
  );

  const userProfileId = getUserProfile().userProfileId;
  const userProfileData = getUserProfile().userProfileData;

  const videoRoomsData = getVideoRooms().videoRoomData;
  const videoRoomId = getVideoRooms().videoRoomId;

  const participantSessionId = getParticipantSessionId();

  const sessionRef = doc(collection(firestore, 'sessions'), sessionId);
  const sessionValues = useFirestoreDocumentData(
    [`sessions`, { sessionId }],
    sessionRef,
    {
      subscribe: true,
    }
  );

  const userId = sessionValues?.data?.userId;

  // const maxParticipants =
  //   videoRoomsData?.maxParticipants >= 10
  //     ? 10
  //     : videoRoomsData?.maxParticipants;

  // const maxParticipants = videoRoomsData?.maxParticipants;
  const maxParticipants = settings.maxParticipants;

  const participantsRef = videoRoomId
    ? collection(firestore, `videoRooms/${videoRoomId}/participants`)
    : null;
  const participantsQuery = query(participantsRef);

  const participantsList = useFirestoreQueryData(
    [
      `videoRooms/${videoRoomId}/participants`,
      { videoRoomId, maxParticipants },
    ],
    participantsQuery,
    {
      subscribe: true,
    },
    {
      enabled: !!videoRoomId,
    }
  );

  const participantsData = participantsList?.data ?? [];

  // const isParticipantInTheRoom = participantsData.find(participant=>participant=)

  const isParticipantInTheRoom = participantsData.filter(
    participant =>
      participant.ParticipantStatus === 'connected' &&
      participantSessionId === participant?.ParticipantIdentity &&
      !String(participant?.ParticipantIdentity).includes('dispatcher')
  );

  const isParticipantInTheRoomBefore = participantsData.filter(
    participant =>
      participant.ParticipantStatus === 'disconnected' &&
      participantSessionId === participant?.ParticipantIdentity
  );

  const participantsConnected = participantsData.filter(
    participant =>
      participant.ParticipantStatus === 'connected' &&
      userProfileId !== participant?.ParticipantIdentity &&
      !String(participant?.ParticipantIdentity).includes('dispatcher')
  );

  const participantsConnectedAll = participantsData.filter(
    participant => participant.ParticipantStatus === 'connected'
  );
  const participantsDisconnected = participantsData.filter(
    participant =>
      participant.ParticipantStatus === 'disconnected' &&
      userProfileId !== participant?.ParticipantIdentity &&
      !String(participant?.ParticipantIdentity).includes('dispatcher')
  );

  // console.log('participantsConnected: ', participantsConnected?.length);
  // console.log('participantsDisconnected: ', participantsDisconnected?.length);

  const participantsConnectedCount = participantsConnected?.length ?? 0;
  const participantsDisconnectedCount = participantsDisconnected?.length ?? 0;

  // console.log('isParticipantInTheRoom: ', isParticipantInTheRoom?.length);

  const isLimitActive = Boolean(
    maxParticipants > participantsConnectedCount ||
      isParticipantInTheRoom?.length
  );

  return (
    <>
      <Box px={0} py={0} className={classes.container} {...otherProps}>
        <div className={classes.videoCameraArea}>
          {sessionValues?.data?.status === 'active' && (
            <>
              {participantsConnectedAll?.length > 0 ? (
                <>
                  {isLimitActive ? (
                    <VideoChat />
                  ) : (
                    <>
                      <Box
                        style={{
                          position: 'relative',
                        }}>
                        <Notification
                          disallowClose
                          opacity={0.8}
                          radius={12}
                          ml={30}
                          mr={30}
                          icon={<IconVideo size={18} />}
                          color="blue"
                          // title="Teal notification"

                          style={{
                            top: 120,
                            // left: '25%',
                            width: '85%',
                            position: 'absolute',
                            zIndex: 1500,
                          }}>
                          <Stack spacing={5} justify="center" align="left">
                            <Text weight={500}>
                              Too many viewers, not enough slots
                            </Text>
                            <Text weight={400}>Please wait...</Text>
                          </Stack>
                        </Notification>
                        <StreamingOffBox />
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <>
                  <StreamingOffBox />
                </>
              )}
            </>
          )}
        </div>
      </Box>
    </>
  );
}

export default VideoCaptureSection;

const useStyles = createStyles(theme => ({
  gridContainer: {
    display: 'grid',
    height: '100%',
    width: '100%',

    // without Icons
    // gridTemplateColumns: '5% auto 5%',
    // gridTemplateColumns: '2% 96% 2%',
    gridTemplateColumns: '1fr',

    // with Icons
    // gridTemplateColumns: '1fr auto 1fr',
    gridTemplateRows: 'auto',
    justifyContent: 'center',
    justifyItems: 'center',

    // Mobile Portrait
    '@media (max-width: 767px)': {
      gridTemplateColumns: '10px auto 10px',
      justifyContent: 'stretch',
    },

    '@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape)':
      {},

    backgroundColor: themeColors.backgound,
  },
  container: {
    '@media (orientation: landscape)': {
      borderWidth: 0,
    },
    backgroundColor: themeColors.backgound,
    width: '100%',
  },

  micArea: {
    height: '100%',
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: themeColors.black,
  },

  lightningArea: {
    height: '100%',
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: themeColors.black,
  },
  videoCameraArea: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
    position: 'relative',
  },

  micIcon: {
    width: 14,
  },
  lightning: {
    width: 10,
  },
  cameraRotate: {
    width: 20,
  },
  videoCamera: {
    width: '100%',
    overflow: 'hidden',
  },
  micIconStyle: {},

  participantVideo: {
    objectFit: 'contain',
    backgroundColor: 'black',
    position: 'relative',
  },
}));
