import React from 'react';
import { createStyles, Loader } from '@mantine/core';

import LogoContainer from './LogoContainer';
import AppDivider from './AppDivider';

import { themeColors, themeSizes } from '../config/themeSettings';

import ProfileSectionData from './ProfileSectionData';
import useUserData from '../store/useUserStore';

function InformationProfileFull({ onClose, children, ...otherProps }) {
  const { classes } = useStyles();

  const { getUserProfile } = useUserData();

  const userProfile = getUserProfile().userProfileData;

  if (!Object.keys(userProfile).length) {
    return (
      <>
        <Loader color="red" size="lg" />
      </>
    );
  }

  return (
    <div className={classes.container} {...otherProps}>
      <LogoContainer />
      <AppDivider />
      <ProfileSectionData />
    </div>
  );
}

export default InformationProfileFull;

const useStyles = createStyles({
  container: {
    backgroundColor: themeColors.black,

    paddingTop: themeSizes.paddingVertical,
    paddingBottom: themeSizes.paddingVertical,
  },

  logoNavigation: {
    display: 'flex',
    alignContent: 'space-between',
    alignItems: 'center',
  },
  grow: {
    width: '100%',
    flexGrow: 1,
  },
});
