import React from 'react';
import { createStyles, Box, Text, Title, List } from '@mantine/core';

function TosPlainText({ children, language = 'en', ...otherProps }) {
  const { classes } = useStyles();

  if (language === 'es')
    return (
      <Box className={classes.container} {...otherProps}>
        <Text size="sm" py={10}>
          Lea atentamente los siguientes Términos de uso ("Términos"). Estos
          Términos regirán su uso de la aplicación móvil Say Help, LLC ("Say
          Help") (la "Aplicación"). Si no acepta estar sujeto a estos Términos,
          elimine de inmediato y cese todo uso de la Aplicación.
        </Text>

        <Text size="sm" py={10}>
          Say Help se reserva el derecho de cambiar los Términos bajo los cuales
          se ofrece la Aplicación a su exclusivo y absoluto criterio en
          cualquier momento y sin previo aviso. Todos los cambios a los Términos
          son efectivos a partir de la fecha de publicación.
        </Text>
        <Title order={4} py={10}>
          Registro para la Aplicación
        </Title>
        <Text size="sm" py={10}>
          La Aplicación utiliza tecnología actual para enviar un mensaje de
          texto y/o una alerta de emergencia a un Punto de Respuesta de
          Seguridad Pública (PSAP) indicando su ubicación actual, GPS e
          información de perfil en caso de que usted dé AYUDA AYUDA AYUDA o otro
          comando de voz identificado por Say Help (el "Comando de voz"). Para
          acceder y utilizar la Aplicación, debe configurar una cuenta de
          usuario ("Su Cuenta") y cumplir con estos Términos. Al configurar y
          mantener su cuenta, acepta proporcionar información precisa sobre su
          identidad, su información de contacto y cualquier otra información
          solicitada por Say Help. Establecerá su propia contraseña para acceder
          al Servicio y será el único y estricto responsable de todo lo que
          ocurra a través del uso de Su Cuenta.
        </Text>
        <Title order={4} py={10}>
          Uso de la Aplicación
        </Title>
        <Text size="sm" py={10}>
          La Aplicación solo puede utilizarse para usos legales y apropiados.
          Say Help se reserva el derecho de realizar cambios en la Aplicación en
          cualquier momento y sin previo aviso. Su acceso y uso de la Aplicación
          puede bloquearse, suspenderse o cancelarse sin previo aviso en
          cualquier momento por cualquier motivo o sin motivo alguno. Aunque la
          siguiente lista no es exhaustiva, su acceso a la Aplicación puede ser
          limitado o revocado por Say Help en cualquier momento por cualquier
          violación de las siguientes reglas:
        </Text>
        <List
          withPadding
          size="sm"
          style={{
            listStylePosition: 'outside',
          }}>
          <List.Item py={10}>
            Debe cumplir con todas las leyes, reglas, leyes estatales, federales
            y/o internacionales. políticas y/o licencias que rigen las
            comunicaciones durante el uso de la Aplicación.
          </List.Item>
          <List.Item py={10}>
            No puede usar la Aplicación de manera inapropiada, como para
            solicitar servicios de emergencias cuando tales servicios no son
            requeridos, o para golpear o acosar a otros.
          </List.Item>
          <List.Item py={10}>
            No puede interferir o intentar interferir con la Aplicación o el uso
            de la Aplicación por parte de otra persona mediante el uso de
            cualquier programa, script, comando, dispositivo, software, rutina o
            de otro modo.
          </List.Item>
          <List.Item py={10}>
            No puede intentar obtener acceso no autorizado a ninguna parte o
            función de la Aplicación, ni intentar usar la Aplicación de otra
            manera que exceda los límites de los derechos que Say Help ha
            otorgado en este documento.
          </List.Item>
          <List.Item py={10}>
            No puede copiar, descompilar ni realizar ingeniería inversa de la
            Aplicación (y/o cualquier algoritmo utilizado para crear la
            Aplicación) y/o crear trabajos derivados basados ​​en la Aplicación.
          </List.Item>
          <List.Item py={10}>
            No puede usar la Aplicación con el fin de desarrollar una aplicación
            que sea similar en naturaleza o propósito a la Aplicación
          </List.Item>
          <List.Item py={10}>
            No puede participar en ninguna actividad que constituya, a exclusivo
            criterio de Say Help, un abuso de la Aplicación.
          </List.Item>
        </List>

        <Title order={4} py={10}>
          Licencia
        </Title>
        <Text size="sm" py={10}>
          Say Help le otorga una licencia limitada, no exclusiva e
          intransferible para acceder y utilizar la Aplicación. Cualquier uso no
          autorizado de la Aplicación rescindirá automáticamente la licencia que
          se le otorga en este documento. Además, Say Help se reserva el derecho
          de revocar esta licencia en cualquier momento y por cualquier motivo.
          Todos los derechos sobre la Aplicación que no se concedan expresamente
          en el presente se considerarán reservados para el uso exclusivo de Say
          Help. No adquirirá ningún derecho, título o interés en la Aplicación
          en virtud de estos Términos, excepto la licencia no exclusiva para
          usar la Aplicación de acuerdo con estos Términos.
        </Text>

        <Title order={4} py={10}>
          Comentarios y resultados
        </Title>
        <Text size="sm" py={10}>
          Es posible que se le solicite que brinde ideas, sugerencias o
          comentarios relacionados con su uso de la Aplicación (colectivamente,
          los "Comentarios"), que Say Help puede utilizar para mejorar la
          Aplicación. Además, Say Help puede rastrear y agregar información
          relacionada con su uso de la Aplicación (colectivamente, los
          "Resultados"). Usted acepta que no deberá hacer ningún reclamo de
          compensación relacionado con el uso de Say Help de los Comentarios o
          Resultados, ni reclamará ningún derecho de propiedad sobre los
          Comentarios o Resultados.
        </Text>

        <Title order={4} py={10}>
          Descargo de responsabilidad de garantías.
        </Title>
        <Text size="sm" py={10}>
          MIENTRAS QUE SAY HELP SE ESFUERZA POR GARANTIZAR QUE LA APLICACIÓN
          FUNCIONARÁ SEGÚN LO PREVISTO, SAY HELP NO GARANTIZA QUE LA APLICACIÓN
          SIEMPRE FUNCIONARÁ EN TODAS LAS CIRCUNSTANCIAS. EN PARTICULAR, LA
          APLICACIÓN DEPENDE DE QUE SU DISPOSITIVO MÓVIL TENGA: (1) UNA SEÑAL
          CELULAR PARA ENVIAR UN MENSAJE DE TEXTO; (2) INFORMACIÓN DE UBICACIÓN
          GPS Y (3) UNA CONEXIÓN DE DATOS. EN AUSENCIA DE CUALQUIERA DE ESTOS
          TRES CRITERIOS, LA APLICACIÓN NO FUNCIONARÁ COMO SE HA DISEÑADO.
          ADEMÁS, PUEDE HABER OTRAS CIRCUNSTANCIAS PRESENTES QUE PROVOQUEN QUE
          LA APLICACIÓN NO RECONOZCA SU COMANDO DE VOZ. SAY HELP NO ASUME
          RESPONSABILIDAD POR SI Y/O CÓMO FUNCIONA SU DISPOSITIVO MÓVIL, LA
          CAPACIDAD DE SU DISPOSITIVO MÓVIL PARA TRABAJAR CON LA APLICACIÓN, O
          LA CAPACIDAD DE LA APLICACIÓN PARA RECONOCER SU COMANDO DE VOZ EN UNA
          CIRCUNSTANCIA DADA. ES ÚNICAMENTE SU RESPONSABILIDAD ASEGURARSE DE QUE
          SU DISPOSITIVO MÓVIL Y SU CONEXIÓN SEAN SUFICIENTES EN TODO MOMENTO.
        </Text>
        <Text size="sm" py={10}>
          EN LA MEDIDA EN QUE LO PERMITA LA LEY APLICABLE, LA APLICACIÓN SE
          PROPORCIONA "TAL CUAL", SIN GARANTÍA DE NINGÚN TIPO, YA SEA EXPLÍCITA
          O IMPLÍCITA. SAY HELP NO GARANTIZA, REPRESENTA O GARANTIZA QUE EL USO
          DE LA APLICACIÓN SERÁ ININTERRUMPIDO, LIBRE DE ERRORES O VIRUS, O QUE
          LA APLICACIÓN CUMPLIRÁ CON SUS REQUISITOS. SAY HELP NIEGA TODAS LAS
          GARANTÍAS, EXPRESAS O IMPLÍCITAS, INCLUYENDO, ENTRE OTRAS, CUALQUIER
          GARANTÍA DE TÍTULO, NO INFRACCIÓN, COMERCIABILIDAD, IDONEIDAD PARA UN
          FIN DETERMINADO O CUALQUIER GARANTÍA QUE SURJA DE UN CURSO DE
          NEGOCIACIÓN, USO COMERCIAL O PRÁCTICA COMERCIAL.
        </Text>

        <Title order={4} py={10}>
          Limitación de responsabilidad.
        </Title>
        <Text size="sm" py={10}>
          USTED RECONOCE Y ACEPTA QUE EL USO DE LA APLICACIÓN ES BAJO SU PROPIO
          RIESGO. NI SAY HELP, NI NINGUNO DE SUS MIEMBROS, GERENTES, EMPLEADOS,
          AFILIADOS, AGENTES, REPRESENTANTES, LICENCIANTES, SUCESORES O
          CESIONARIOS (COLECTIVAMENTE, “SAY HELP ASOCIADOS”) SERÁN RESPONSABLES
          ANTE USTED POR CUALQUIER CUALQUIER COMPENSACIÓN, DIRECTA, INDIRECTA,
          INCIDENTAL, DAÑOS ESPECIALES, EJEMPLARES, PUNITIVOS O CONSECUENTES U
          HONORARIOS DE ABOGADOS O POR PÉRDIDA DE DATOS O PÉRDIDA DE BENEFICIOS
          QUE SURJAN DE: (A) EL USO DE LA APLICACIÓN; (B) LA IMPOSIBILIDAD DE
          OBTENER ACCESO O UTILIZAR LA APLICACIÓN; Y/O (C) DIGA AYUDA AL
          INCUMPLIMIENTO DE ESTOS TÉRMINOS.
        </Text>
        <Text size="sm" py={10}>
          LAS ANTERIORES EXCLUSIONES DE RESPONSABILIDAD SE APLICARÁN INCLUSO SI
          SAY HELP HA SIDO ADVERTIDO DE LA POSIBILIDAD DE TALES DAÑOS O TALES
          DAÑOS FUERON PREVISIBLES. SU ÚNICO DERECHO Y RECURSO CON RESPECTO A
          CUALQUIER DISPUTA CON SAY HELP ES DEJAR DE UTILIZAR LA APLICACIÓN.
          DEBIDO A QUE ALGUNOS ESTADOS O JURISDICCIONES NO PERMITEN LA EXCLUSIÓN
          O LIMITACIÓN DE RESPONSABILIDAD POR DAÑOS CONSECUENTES, INCIDENTALES O
          ESPECIALES, EN DICHOS ESTADOS Y JURISDICCIONES LA RESPONSABILIDAD SE
          LIMITA A LA MÁXIMA MEDIDA PERMITIDA POR LA LEY.
        </Text>

        <Title order={4} py={10}>
          Indemnización.
        </Title>
        <Text size="sm" py={10}>
          Usted acepta indemnizar, defender y eximir de responsabilidad a Say
          Help y a los asociados de Say Help de todas las pérdidas,
          responsabilidades, gastos, daños, reclamaciones, demandas y costos,
          incluidos los honorarios razonables de abogados y los costos
          judiciales, relacionados con o que surjan de: (a) cualquier violación
          de estos Términos por su parte; y (b) reclamos presentados por
          terceros, como la aplicación de la ley, que resultan de su uso de la
          Aplicación.
        </Text>

        <Title order={4} py={10}>
          Política de privacidad.
        </Title>
        <Text size="sm" py={10}>
          Say Help respeta y se compromete con su privacidad. Revise la Política
          de privacidad de Say Help{' '}
          <a
            href="https://sayhelp.com/privacy/es"
            target="_blank"
            rel="noreferrer">
            Política de privacidad
          </a>
          , que también rige su uso de la Aplicación, para comprender las
          prácticas de Say Help.
        </Text>

        <Title order={4} py={10} align="left">
          Miceláneas.
        </Title>
        <Text size="sm" py={10}>
          Las leyes del Estado de Wyoming regirán la validez, ejecución,
          cumplimiento, interpretación y cualquier otro aspecto de estos
          Términos, sin tener en cuenta los principios de conflicto de leyes en
          virtud de los mismos. Las partes acuerdan someterse a la jurisdicción
          y competencia exclusivas de los tribunales del condado de Johnson,
          Wyoming, para cualquier acción que surja de estos Términos. Say Help
          tendrá derecho a que usted le pague todos y cada uno de los gastos de
          bolsillo, incluidos, entre otros, los honorarios de abogados,
          incurridos por Say Help en relación con el cumplimiento de estos
          Términos.
        </Text>
        <Text size="sm" py={10}>
          Usted reconoce y acepta que las disposiciones, las divulgaciones y las
          exenciones de responsabilidad establecidas en estos Términos son
          justas y razonables y su acuerdo de seguirlos y estar sujeto a ellos
          no es el resultado de fraude, coacción o influencia indebida ejercida
          sobre usted por parte de cualquier persona o entidad. El hecho de que
          Say Help no ejerza o haga cumplir cualquier derecho o disposición de
          estos Términos no constituirá una renuncia a tal derecho o
          disposición. Si un tribunal de jurisdicción competente determina que
          alguna disposición de estos Términos no es válida, las partes
          acuerdan, sin embargo, que el tribunal debe esforzarse por hacer
          efectivas las intenciones de las partes tal como se refleja en la
          disposición, y las demás disposiciones de los Términos permanecerán en
          total fuerza y ​​efecto.
        </Text>

        <Text size="sm" py={10}>
          Say Help tendrá el derecho de asignar estos Términos y sublicenciar
          cualquiera y todos sus derechos bajo estos Términos. Estos Términos,
          incluidos los documentos a los que se hace referencia en este
          documento y los términos y condiciones adicionales publicados a través
          de la Aplicación, representan el entendimiento total entre usted y Say
          Help con respecto a la Aplicación. Estos Términos reemplazan todos los
          acuerdos escritos u orales anteriores entre usted y Say Help con
          respecto a dicho tema. Sin perjuicio de cualquier disposición de estos
          Términos, Say Help tiene disponibles todos los recursos legales o de
          equidad para hacer cumplir estos Términos.
        </Text>
        <Text size="sm" py={10}></Text>
      </Box>
    );

  if (language === 'en' || 1)
    return (
      <Box className={classes.container} {...otherProps}>
        <Text size="sm" py={10}>
          Please read the following Terms of Use (“Terms”) carefully. These
          Terms will govern your use of Say Help, LLC (“Say Help”) mobile
          application (the “Application”). If you do not agree to be bound by
          these Terms, please promptly delete and cease all use of the
          Application.
        </Text>

        <Text size="sm" py={10}>
          Say Help reserves the right to change the Terms under which the
          Application is offered in its sole and absolute discretion at any time
          without notice. All changes to the Terms are effective from the date
          posted.
        </Text>
        <Title order={4} py={10}>
          Registration for the Application
        </Title>
        <Text size="sm" py={10}>
          The Application uses current technology to send a text message and/or
          an emergency alert to a Public Safety Answering Point (PSAP)
          indicating your current location, GPS, and profile information in the
          event that you give the HELP HELP HELP or other voice command
          identified by Say Help (the “Voice Command”). In order to access and
          use the Application, you must set up a user account (“Your Account”)
          and abide by these Terms. In setting up and maintaining Your Account,
          you agree to provide accurate information regarding your identity,
          your contact information, and any other information requested by Say
          Help. You will set your own password for accessing the Service and
          shall be solely and strictly liable for everything that occurs through
          the use of Your Account.
        </Text>
        <Title order={4} py={10}>
          Use of the Application
        </Title>
        <Text size="sm" py={10}>
          The Application may only be used for legal and appropriate uses. Say
          Help reserves the right to make changes to the Application at any time
          and without notice. Your access to and use of the Application may be
          blocked, suspended, or terminated without prior notice at any time for
          any reason or no reason at all. Although the following list is not
          exhaustive, your access to the Application may be limited or revoked
          by Say Help at any time for any violation of the following rules:
        </Text>
        <List
          withPadding
          size="sm"
          style={{
            listStylePosition: 'outside',
          }}>
          <List.Item py={10}>
            You must comply with all state, federal, and/or international laws,
            rules, policies and/or licenses governing communications while using
            the Application.
          </List.Item>
          <List.Item py={10}>
            You may not use the Application inappropriately, such as to request
            emergency services when such services are not required, or to swat
            or harass others.
          </List.Item>
          <List.Item py={10}>
            You may not interfere or attempt to interfere with the Application
            or another person’s use of the Application by use of any program,
            script, command, device, software, routine, or otherwise.
          </List.Item>
          <List.Item py={10}>
            You may not attempt to gain unauthorized access to any portion or
            feature of the Application, or otherwise attempt to use the
            Application in a manner that exceeds the limits of the rights that
            Say Help has granted herein.
          </List.Item>
          <List.Item py={10}>
             You may not copy, decompile, and/or reverse-engineer the
            Application (and/or any algorithms used in creating the Application)
            and/or create derivative works based upon the Application.
          </List.Item>
          <List.Item py={10}>
            You may not use the Application for the purpose of developing an
            application that is similar in nature or purpose to the Application.
          </List.Item>
          <List.Item py={10}>
            You may not engage in any activities that constitutes, in Say Help’s
            sole discretion, an abuse of the Application.
          </List.Item>
        </List>

        <Title order={4} py={10}>
          License
        </Title>
        <Text size="sm" py={10}>
          Say Help grants you a limited, non-exclusive, non-transferable license
          to access and use the Application. Any unauthorized use of the
          Application shall automatically terminate the license granted to you
          herein. Further, Say Help reserves the right to revoke this license at
          any time and for any reason. All rights in the Application not
          expressly granted herein shall be deemed as reserved for the exclusive
          use by Say Help. You will not acquire any right, title or interest in
          or to the Application by reason of these Terms, except for the
          non-exclusive license to use the Application in accordance with these
          Terms.
        </Text>

        <Title order={4} py={10}>
          Feedback and Results
        </Title>
        <Text size="sm" py={10}>
          You may be asked to provide ideas, suggestions, or feedback relating
          to your use of the Application (collectively, the “Feedback”), which
          may be used by Say Help to improve the Application. In addition, Say
          Help may track and aggregate information relating to your use of the
          Application (collectively, the “Results”). You agree that you shall
          not have any claim to compensation relating to Say Help’s use of any
          the Feedback or Results, nor shall you claim any ownership rights in
          the Feedback or Results.
        </Text>

        <Title order={4} py={10}>
          Disclaimer of Warranties
        </Title>
        <Text size="sm" py={10}>
          WHILE SAY HELP ENDEAVORS TO ENSURE THAT THE APPLICATION WILL WORK AS
          INTENDED, SAY HELP DOES NOT GUARANTEE THAT THE APPLICATION WILL ALWAYS
          FUNCTION IN ALL CIRCUMSTANCES. IN PARTICULAR, THE APPLICATION IS
          DEPENDENT ON YOUR MOBILE DEVICE HAVING: (1) A CELLULAR SIGNAL TO SEND
          A TEXT MESSAGE; (2) GPS LOCATION INFORMATION, AND (3) A DATA
          CONNECTION. ABSENT ANY ONE OF THESE THREE CRITERIA, THE APPLICATION
          WILL NOT FUNCTION AS DESIGNED. MOREOVER, THERE COULD BE OTHER
          CIRCUMSTANCES PRESENT THAT CAUSE THE APPLICATION TO NOT RECOGNIZE YOUR
          VOICE COMMAND. SAY HELP ASSUMES NO RESPONSIBILITY FOR IF AND/OR HOW
          YOUR MOBILE DEVICE IS FUNCTIONING, YOUR MOBILE DEVICE’S ABILITY TO
          WORK WITH THE APPLICATION, AND/OR THE APPLICTATION’S ABILITY TO
          RECONGINZE YOUR VOICE COMMAND IN A GIVEN CIRCUMSTANCE. IT IS SOLELY
          YOUR RESPONSIBILITY TO ENSURE THAT YOUR MOBILE DEVICE AND ITS
          CONNECTION IS SUFFICIENT AT ALL TIMES.
        </Text>
        <Text size="sm" py={10}>
          TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE APPLICATION IS PROVIDED
          “AS IS,” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. SAY
          HELP DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT THE USE OF THE
          APPLICATION WILL BE UNINTERRUPTED, ERROR FREE, OR VIRUS FREE, OR THAT
          THE APPLICATION WILL MEET YOUR REQUIREMENTS. SAY HELP DISCLAIMS ALL
          WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO ANY
          WARRANTY OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, OR ANY WARRANTY ARISING FROM A COURSE OF DEALING,
          TRADE USAGE, OR TRADE PRACTICE.
        </Text>

        <Title order={4} py={10}>
          Limitation of Liability
        </Title>
        <Text size="sm" py={10}>
          YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE APPLICATION IS AT YOUR
          SOLE RISK. NEITHER SAY HELP, NOR ANY OF ITS MEMBERS, MANAGERS,
          EMPLOYEES, AFFILIATES, AGENTS, REPRESENTATIVES, LICENSORS, SUCCESSORS,
          OR ASSIGNS (COLLECTIVELY, “SAY HELP ASSOCIATES”) SHALL BE LIABLE TO
          YOU FOR ANY COMPENSATORY, DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
          EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, OR ATTORNEYS’ FEES, OR
          FOR LOST DATA OR LOST PROFIT, ARISING OUT OF: (A) THE USE OF THE
          APPLICATION; (B) THE INABILITY TO GAIN ACCESS TO OR USE THE
          APPLICATION; AND/OR (C) SAY HELP’S BREACH OF THESE TERMS. THE
          FOREGOING EXCLUSIONS FROM LIABILITY SHALL BE APPLICABLE EVEN IF SAY
          HELP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH
          DAMAGES WERE FORESEEABLE.
        </Text>
        <Text size="sm" py={10}>
          YOUR SOLE RIGHT AND REMEDY WITH RESPECT TO ANY DISPUTE WITH SAY HELP
          IS TO STOP USING THE APPLICATION. BECAUSE SOME STATES OR JURISDICTIONS
          DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
          CONSEQUENTIAL, INCIDENTAL OR SPECIAL DAMAGES, IN SUCH STATES AND
          JURISDICTIONS LIABILITY IS LIMITED TO THE GREATEST EXTENT PERMITTED BY
          LAW.
        </Text>

        <Title order={4} py={10}>
          Indemnification
        </Title>
        <Text size="sm" py={10}>
          You agree to indemnify, defend and hold harmless Say Help and the Say
          Help Associates from and against all losses, liabilities, expenses,
          damages, claims, demands and costs, including reasonable attorneys’
          fees and court costs, relating to or arising from: (a) any violation
          of this Terms by you; and (b) claims asserted by third parties, such
          as law enforcement, that result from your use of the Application.
        </Text>

        <Title order={4} py={10}>
          Privacy Policy
        </Title>
        <Text size="sm" py={10}>
          Say Help respects and is committed to your privacy. Please review Say
          Help’s{' '}
          <a
            href="https://sayhelp.com/privacy"
            target="_blank"
            rel="noreferrer">
            Privacy Policy
          </a>
          , which also governs your use of the Application, to understand Say
          Help’s practices.
        </Text>

        <Title order={4} py={10} align="left">
          Miscellaneous
        </Title>
        <Text size="sm" py={10}>
          The laws of the State of Wyoming shall govern the validity,
          performance, enforcement, interpretation and any other aspect of these
          Terms, without regard to principles of conflicts of laws thereunder.
          The parties agree to submit to the exclusive jurisdiction and venue of
          the courts of Johnson County, Wyoming for any action arising out of
          these Terms. Say Help shall be entitled to payment from you for any
          and all out-of-pocket costs, including, without limitation, attorneys'
          fees, incurred by Say Help in connection with enforcing these Terms.
        </Text>
        <Text size="sm" py={10}>
          You acknowledge and agree that the provisions, disclosures and
          disclaimers set forth in these Terms are fair and reasonable and your
          agreement to follow and be bound by them is not the result of fraud,
          duress or undue influence exercised upon you by any person or entity.
          The failure of Say Help to exercise or enforce any right or provision
          of these Terms shall not constitute a waiver of such right or
          provision. If any provision of these Terms is found by a court of
          competent jurisdiction to be invalid, the parties nevertheless agree
          that the court should endeavor to give effect to the parties’
          intentions as reflected in the provision, and the other provisions of
          the Terms shall remain in full force and effect.
        </Text>

        <Text size="sm" py={10}>
          Say Help shall have the right to assign these Terms and to sublicense
          any and all of its rights under these Terms. These Terms, including
          any documents referenced herein and any additional terms and
          conditions as posted via the Application, represent the entire
          understanding between you and Say Help regarding the Application.
          These Terms supersede all previous written or oral agreements between
          you and Say Help with respect to such subject matter. Notwithstanding
          any provision of these Terms, Say Help has available all remedies at
          law or equity to enforce these Terms.
        </Text>
        <Text size="sm" py={10}></Text>
      </Box>
    );
}

export default TosPlainText;

const useStyles = createStyles({
  container: {},
});
