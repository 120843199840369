import React from 'react';
import { createStyles, Box, Stack, Space } from '@mantine/core';
import ProfileLocationListItem from './ProfileLocationListItem';

import {
  getCourseFromDegrees,
  processSpeed,
  showField,
} from '../utils/formatProfile';
import useUserData from '../store/useUserStore';

function LocationProfileSection({ children, ...otherProps }) {
  const { classes } = useStyles();
  const {
    getLastKnownLocationData,
    getInitialLocationData,
    getCurrentLocationData,
  } = useUserData();

  let profileLocationListLatestData = [];

  const lastKnownLocationData = getLastKnownLocationData();

  const initialLocationData = getInitialLocationData();

  const currentLocationData = getCurrentLocationData();

  const isInitialLocationData = Boolean(
    Object.keys(initialLocationData).length
  );

  const isCurrentLocationData = Boolean(
    Object.keys(currentLocationData).length
  );

  const isLastKnownLocationData = Boolean(
    Object.keys(lastKnownLocationData).length
  );

  if (isInitialLocationData || isCurrentLocationData)
    profileLocationListLatestData = [
      {
        profileLocationName: 'Initial Location',
        profileLocationData:
          showField(initialLocationData.latitude, 10) +
          ', ' +
          showField(initialLocationData.longitude, 10),
        profileLocationLink:
          showField(initialLocationData.latitude) +
          ',' +
          showField(initialLocationData.longitude),
      },
      {
        profileLocationName: 'Current Location',
        profileLocationData:
          showField(currentLocationData.latitude, 10) +
          ', ' +
          showField(currentLocationData.longitude, 10),
        profileLocationLink:
          showField(currentLocationData.latitude) +
          ',' +
          showField(currentLocationData.longitude, 5),
      },
      {
        profileLocationName: 'Speed',
        profileLocationData: processSpeed(
          showField(currentLocationData.speed, 5)
        ),
      },
      {
        profileLocationName: 'Direction',
        profileLocationData: getCourseFromDegrees(
          showField(currentLocationData.course)
        ),
      },
      {
        profileLocationName: 'Altitude',
        profileLocationData: showField(currentLocationData.altitude),
      },
    ];

  if (isLastKnownLocationData && !isInitialLocationData)
    profileLocationListLatestData = [
      {
        profileLocationName: 'Last Known Location',
        profileLocationData:
          showField(lastKnownLocationData.latitude, 10) +
          ', ' +
          showField(lastKnownLocationData.longitude, 10),
        profileLocationLink:
          showField(lastKnownLocationData.latitude) +
          ',' +
          showField(lastKnownLocationData.longitude),
      },
      {
        profileLocationName: 'Current Location',
        profileLocationData:
          showField(currentLocationData.latitude, 10) +
          ', ' +
          showField(currentLocationData.longitude, 10),
        profileLocationLink:
          showField(currentLocationData.latitude) +
          ',' +
          showField(currentLocationData.longitude, 5),
      },
      {
        profileLocationName: 'Last Speed',
        profileLocationData: processSpeed(
          showField(lastKnownLocationData.speed, 5)
        ),
      },
      {
        profileLocationName: 'Last Direction',
        profileLocationData: getCourseFromDegrees(lastKnownLocationData.course),
      },
      {
        profileLocationName: 'Last Altitude',
        profileLocationData: showField(lastKnownLocationData.altitude),
      },
    ];

  let profileLocationTitleText = 'Latest Data';

  if (!isLastKnownLocationData && !isCurrentLocationData)
    profileLocationTitleText = 'Waiting for data...';

  if (isLastKnownLocationData && !isInitialLocationData)
    profileLocationTitleText = 'Latest Known Data';

  return (
    <Box className={classes.container} {...otherProps}>
      <Stack align="stretch" justify="left" spacing={0}>
        <ProfileLocationListItem
          profileLocationTitle={profileLocationTitleText}
          profileLocationList={profileLocationListLatestData}
        />
        <Space h={70} w={10} />
      </Stack>
    </Box>
  );
}

export default LocationProfileSection;

const useStyles = createStyles({
  container: {
    paddingLeft: 25,
  },

  divider: {
    paddinTop: 10,
    paddingBottom: 10,
  },
});
