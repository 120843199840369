import React from 'react';
import { createStyles } from '@mantine/core';
import { themeColors } from '../config/themeSettings';

function AppContainer({ children, ...other }) {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.container} {...other}>
        {children}
      </div>
    </>
  );
}

export default AppContainer;

const useStyles = createStyles({
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: themeColors.backgound,
  },
});
