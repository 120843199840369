import React, { useState, useEffect, useRef } from 'react';

import {
  createStyles,
  Box,
  Badge,
  Center,
  Group,
  ActionIcon,
  Text,
  Paper,
  Progress,
  Image,
  Tooltip,
  Stack,
  Loader,
  ThemeIcon,
} from '@mantine/core';

import {
  MdFlipCameraAndroid,
  MdHeadset,
  MdVolumeOff,
  MdVolumeUp,
} from 'react-icons/md';

import AudioAnalyser from './AudioAnalyser';
import {
  IconCamera,
  IconCameraRotate,
  IconDots,
  IconEye,
  IconMicrophone,
  IconPoint,
} from '@tabler/icons';
import useUserData from '../../store/useUserStore';
import useUserUi from '../../store/useUserUi';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import { themeColors } from '../../config/themeSettings';

import logger from '../../utils/logger';
import { toast } from 'react-hot-toast';

import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestAudioPermissions,
  requestMediaPermissions,
} from 'mic-check';
import { firestore } from '../../firebase';
import {
  useFirestoreDocumentData,
  useFirestoreDocumentMutation,
  useFirestoreQueryData,
} from '@react-query-firebase/firestore';
import { collection, doc, limit, query, where } from 'firebase/firestore';
import AppTooltip from '../AppTooltip';

let Audio = () => (
  <>
    <p style={{ color: 'white' }}>Loading audio...</p>
  </>
);

const streamingFormatSelector = state => state.streamingFormat;
const sessionsSelector = state => state.sessions;
const toggleMuteSelector = state => state.toggleMute;
const toggleCameraSwitchSelector = state => state.toggleCameraSwitch;

const streamMuteSelector = state => state.streamMute;
const streamUnmuteSelector = state => state.streamUnmute;

const Participant = ({
  participant,
  participantCount,
  dispatchersCount = 0,
}) => {
  const { classes } = useStyles();
  const { setVideoRoomStatus } = useUserData();
  const { setRoomOptions, getRoomOptions } = useUserData();
  const { getVideoRooms } = useUserData();
  const { getUserUi } = useUserUi();
  const userUi = getUserUi();
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const [audioVis, setAudioVis] = useState();
  const [videoVis, setVideoVis] = useState();
  const [source, setSource] = useState('');

  const [cameraState, setCameraState] = useState({});

  const [streamPlayingStatus, setStreamPlayingStatus] = useState(false);

  const [isAttachedVideo, setIsAttachedVideo] = useState(false);
  const [isAttachedAudio, setIsAttachedAudio] = useState(false);

  const [unmuteMessage, setUnmuteMessage] = useState(true);

  const [tooltipFirstMessage, setTooltipFirstMessage] = useState(true);

  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const canvasRef = useRef();

  // Front and Rear Camera Switch disabled states
  // disabled = false by default.

  const [cameraSwitchStatusActive, setCameraSwitchStatusActive] =
    useState(false);

  const [audioPeaks, setAudioPeaks] = useState([{}]);

  const streamingFormat = useUserUi(streamingFormatSelector);
  const toggleMute = useUserUi(toggleMuteSelector);
  const toggleCameraSwitch = useUserUi(toggleCameraSwitchSelector);

  const sessionCurrent = useUserData(sessionsSelector);

  const setStreamingFormat = useUserUi(state => state.setStreamingFormat);

  const handleToggleMute = useUserUi(state => state.handleToggleMute);

  const handleToggleCameraSwitch = useUserUi(
    state => state.handleToggleCameraSwitch
  );

  const streamMute = useUserUi(state => state.streamMute);
  const streamUnmute = useUserUi(state => state.streamUnmute);

  // Get Video Room Data via React Query Hook
  const videoRoomId = getVideoRooms().videoRoomId;

  const videoRoomsRef = videoRoomId
    ? query(doc(collection(firestore, 'videoRooms'), videoRoomId))
    : null;

  // Write VideoRooms Document Hook - just declaration, to use it use videoRoomsMutation.mutation({ fields})
  const videoRoomsMutation = useFirestoreDocumentMutation(videoRoomsRef, {
    merge: true,
  });

  // Read VideoRooms Document Hook
  const videoRoomsQuery = useFirestoreDocumentData(
    [`videoRooms/${videoRoomId}/cameraState`, { videoRoomId }],
    videoRoomsRef,
    {
      subscribe: true,
    },
    {
      enabled: !!videoRoomsRef,
    }
  );

  // Show Error Message as Toast when switching camera trigger Error
  useEffect(() => {
    if (videoRoomsQuery?.data?.cameraState?.error) {
      toast(videoRoomsQuery?.data?.cameraState?.error);
      console.log(videoRoomsQuery?.data?.cameraState?.error);
    }
  }, [videoRoomsQuery?.data?.cameraState?.error]);

  useEffect(() => {
    if (
      videoRoomsQuery?.data?.cameraState?.source !==
      videoRoomsQuery?.data?.cameraState?.pendingCameraSource
    ) {
      toast(`Switching Camera...`, {
        duration: 1000,
        style: {
          width: '50%',
        },
      });
    }
  }, [videoRoomsQuery?.data?.cameraState?.pendingCameraSource]);

  function handleCameraSwitch() {
    const cameraState = {
      loading: true,
      pendingCameraSource:
        videoRoomsQuery?.data?.cameraState?.source?.toLowerCase() === 'front'
          ? 'rear'
          : 'front',
    };

    // Disable Camera Switch Buttons when receive request to change camera view
    setCameraSwitchStatusActive(true);

    videoRoomsMutation.mutate(
      {
        cameraState,
      },
      {
        onSuccess: () => {
          setCameraState(cameraState);

          // Enable Camera Switch Buttons after request successfully changed camera
          // with allowing switch back in 1 sec
          setTimeout(() => {
            setCameraSwitchStatusActive(false);
          }, 1000);
        },
        onError: () => {
          toast.error('Failed to switch camera', {
            duration: 1000,
            style: {
              width: '40%',
            },
          });

          // Enable Camera Switch Buttons after request failed
          setCameraSwitchStatusActive(false);
        },
      }
    );
  }

  const checkMicPermissions = () => {
    if (getRoomOptions()?.audio)
      requestMediaPermissions(getRoomOptions())
        .then(response => {
          if (response) {
            console.log('Mic Access Approved');
          }
        })
        .catch(err => {
          console.log(err);
          toast('Allow Microphone Access', {
            icon: <IconMicrophone stroke={1.5} />,
          });
        });
  };

  const mediaQueryDesktop = useMediaQuery(`(min-width: 1024px)`);

  const mediaQueryMobileLandscape = useMediaQuery(
    `(max-width: 1023px) and (orientation: landscape)`
  );

  const mediaQueryMobilePortrait = useMediaQuery(
    `(min-width: 768px) and (max-width: 1023px) and (orientation: portrait)`
  );

  const mediaQueryTabletPortrait = useMediaQuery(
    `(min-width: 768px) and (max-width: 1023px) and (orientation: portrait)`
  );

  const mediaQueryTabletLandscape = useMediaQuery(
    `(min-width: 768px) and (max-width: 1023px) and (orientation: landscape)`
  );

  const { height, width } = useViewportSize();

  const videoHeight = height - 70; // 70 - height of top header

  // setVideoRoomStatus(true);

  let newVideoHeight = videoHeight;

  if (
    mediaQueryMobileLandscape ||
    mediaQueryTabletLandscape ||
    mediaQueryTabletPortrait
  )
    newVideoHeight = videoHeight;
  else newVideoHeight = Math.round(videoHeight * 0.38); // 250px for normal mobile

  /// mediaQueryTabletLandscape

  if (mediaQueryDesktop) newVideoHeight = 270;

  const handlePlay = () => {
    if (videoRef) {
      if (isAttachedVideo) videoRef?.current?.play();
      // if (isAttachedAudio) audioRef?.current?.play();
      streamUnmute();
      setStreamPlayingStatus(true);
    }
  };

  const handlePause = () => {
    if (videoRef) {
      videoRef?.current?.pause();
      streamMute();
      setStreamPlayingStatus(false);
    }
  };

  const handleMicAccess = () => {
    checkMicPermissions();
    streamMute();
    setTimeout(() => {
      setRoomOptions({
        audio: !getRoomOptions()?.audio,
        video: false,
      });
      streamUnmute();
    }, 200);
  };

  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else if (track.kind === 'audio') {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant, getRoomOptions()]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      if (isAttachedVideo) videoTrack.detach();
      videoTrack.attach(videoRef.current);
      setVideoRoomStatus(true);

      setIsAttachedVideo(true);
      return () => {
        videoTrack.detach();
        setVideoRoomStatus(false);
        setIsAttachedVideo(false);
      };
    }
  }, [videoTracks, streamingFormat]);

  useEffect(() => {
    const audioTrack = audioTracks[0];

    if (audioTrack) {
      if (isAttachedAudio) audioTrack.detach();
      if (!toggleMute) {
        audioTrack.attach(audioRef.current);
        setIsAttachedAudio(true);
      }
      setAudioVis(audioTrack);
      setVideoRoomStatus(true);

      Audio = () => (
        <>
          <AudioAnalyser audio={audioTrack} width="100vw" height={90} />
        </>
      );

      return () => {
        audioTrack.detach();
        setVideoRoomStatus(false);
        setIsAttachedAudio(false);
      };
    }
  }, [audioTracks, streamingFormat, toggleMute]);

  // Video and Audio handling
  useEffect(() => {
    if (videoTracks?.length ?? false) setStreamingFormat('video');
    if (audioTracks?.length && !videoTracks?.length)
      setStreamingFormat('audio');

    const streamLogs =
      JSON.stringify(audioTracks) + ', ' + JSON.stringify(videoTracks);
    logger.leaveBreadcrumb('audio and video stream: ' + streamLogs);
  }, [audioTracks, videoTracks]);

  const eyeOutline = participantCount ? (
    <Group position="center" spacing={5} pl={5}>
      {dispatchersCount > 0 && (
        <>
          <AppTooltip label="Dispatchers" openDelay="50">
            <Box>
              <MdHeadset color="white" size={10} />
            </Box>
          </AppTooltip>
          <Text sx={{ color: 'white', fontSize: '0.7rem' }}>
            {dispatchersCount}
          </Text>
        </>
      )}
      <AppTooltip label="Viewers" openDelay="50">
        <Box>
          <IconEye color="white" size={10} />
        </Box>
      </AppTooltip>
      <Text sx={{ color: 'white', fontSize: '0.7rem' }}>
        {participantCount}
      </Text>
    </Group>
  ) : (
    ''
  );

  if (streamingFormat && streamingFormat === 'video')
    return (
      <>
        <div className={classes.position}>
          <div className={classes.statusVideo}>
            <Stack spacing={5}>
              <Paper
                px={10}
                py={5}
                shadow="sm"
                radius="xl"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  zIndex: 1,
                }}>
                <Stack justify="center">
                  <Group spacing={15}>
                    {eyeOutline}
                    <Badge
                      color="red"
                      variant="filled"
                      size="xs"
                      sx={{
                        textTransform: 'none !important',
                      }}>
                      <Group spacing={5} position="center">
                        <IconPoint size={7} stroke={5} />

                        <Text weight={700}>Live Video</Text>
                      </Group>
                    </Badge>
                    {/* {videoRoomsQuery?.data?.cameraState && (
                      <>
                        {videoRoomsQuery?.data?.cameraState?.source ===
                          'rear' && (
                          <IconCameraRotate
                            size={16}
                            color="white"
                            stroke={1.5}
                          />
                        )}
                      </>
                    )} */}
                  </Group>
                </Stack>
              </Paper>
            </Stack>
          </div>
        </div>
        {/* <Box className={classes.micButton}>
          <Tooltip
            label={getRoomOptions()?.audio ? 'Turn Off Mic' : 'Turn On Mic'}
            events={{ hover: true, focus: true, touch: true }}
            color="dark"
            radius="md"
            position="left"
            transition="fade"
            openDelay={250}
            transitionDuration={450}
            sx={{ fontSize: 11 }}
            withArrow>
            <ActionIcon
              color={getRoomOptions()?.audio ? 'red' : 'dark'}
              radius="xl"
              size={40}
              style={{
                backgroundColor: getRoomOptions()?.audio
                  ? 'rgba(235, 51, 35, 1)'
                  : 'rgba(255, 255, 255, 0.8)',
              }}
              className={classes.controls}
              onClick={handleMicAccess}>
              {getRoomOptions()?.audio ? (
                <MdMic size={25} color="white" />
              ) : (
                <MdMicOff size={25} />
              )}
            </ActionIcon>
          </Tooltip>
        </Box> */}
        <Box className={classes.muteButton}>
          <Tooltip
            label={toggleMute ? 'Unmute' : 'Mute'}
            events={{ hover: true, focus: true, touch: true }}
            color="dark"
            radius="md"
            position="bottom"
            transition="fade"
            openDelay={250}
            transitionDuration={450}
            sx={{ fontSize: 11 }}
            withArrow>
            <ActionIcon
              variant="light"
              color="dark"
              radius="xl"
              size={40}
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
              }}
              className={classes.controls}
              onClick={() => {
                handleToggleMute();
                setTooltipFirstMessage(false);
              }}>
              {toggleMute ? (
                <MdVolumeOff size={25} />
              ) : (
                <MdVolumeUp size={25} />
              )}
            </ActionIcon>
          </Tooltip>
        </Box>
        {videoRoomsQuery?.data?.cameraState && (
          <Box className={classes.cameraSwitchButton}>
            {videoRoomsQuery?.data?.cameraState?.loading &&
            !videoRoomsQuery?.data?.error ? (
              <>
                <ThemeIcon
                  size={40}
                  variant="light"
                  color="dark"
                  radius="xl"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  }}>
                  <Loader color="dark" size="sm" />
                </ThemeIcon>
              </>
            ) : (
              <>
                {videoRoomsQuery?.data?.cameraState?.source?.toLowerCase() ===
                'front' ? (
                  <>
                    {videoRoomsQuery?.data?.cameraState?.rearSupported && (
                      <Tooltip
                        label="Switch to Rear Camera"
                        events={{ hover: true, focus: true, touch: true }}
                        color="dark"
                        radius="md"
                        position="bottom"
                        transition="fade"
                        openDelay={250}
                        transitionDuration={450}
                        sx={{ fontSize: 11 }}
                        withArrow>
                        <ActionIcon
                          component="button"
                          disabled={cameraSwitchStatusActive}
                          variant="default"
                          color="dark"
                          radius="xl"
                          size={40}
                          className={classes.controls}
                          onClick={handleCameraSwitch}>
                          <MdFlipCameraAndroid size={25} stroke={2} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <>
                    {videoRoomsQuery?.data?.cameraState?.frontSupported && (
                      <Tooltip
                        label="Switch to Front Camera"
                        events={{ hover: true, focus: true, touch: true }}
                        color="dark"
                        radius="md"
                        position="bottom"
                        transition="fade"
                        openDelay={250}
                        transitionDuration={450}
                        sx={{ fontSize: 11 }}
                        withArrow>
                        <ActionIcon
                          disabled={cameraSwitchStatusActive}
                          variant="default"
                          color="dark"
                          radius="xl"
                          size={40}
                          className={classes.controls}
                          onClick={handleCameraSwitch}>
                          <MdFlipCameraAndroid size={25} stroke={2} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        )}
        <Box className={classes.bg}>
          <div
            className="participant"
            style={{ height: `${newVideoHeight}px` }}>
            <video
              ref={videoRef}
              autoPlay={true}
              // muted={toggleMute}
              muted
              className="participantVideo"
              width="100%"
              height={newVideoHeight}
              style={{ width: '100%' }}
              // poster={videoPoster}
            />
            <audio
              id="video-audio"
              ref={audioRef}
              // autoPlay={true}
              muted={toggleMute}
              className="participantAudio"
            />
          </div>
        </Box>
      </>
    );

  if (streamingFormat && streamingFormat === 'audio')
    return (
      <>
        <div className={classes.position}>
          <div className={classes.statusAudio}>
            <Paper
              px={7}
              py={2}
              shadow="sm"
              radius="xl"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                zIndex: 1,
              }}>
              <Group spacing={15}>
                {eyeOutline}

                <Badge
                  color="red"
                  variant="filled"
                  size="xs"
                  sx={{
                    textTransform: 'none !important',
                  }}>
                  <Group spacing={5} position="center">
                    <IconPoint size={7} stroke={5} />
                    <Text weight={700}>Live Audio</Text>
                  </Group>
                </Badge>
              </Group>
            </Paper>
          </div>
        </div>

        {/* <Box className={classes.micButton}>
          <Tooltip
            label={getRoomOptions()?.audio ? 'Turn Off Mic' : 'Turn On Mic'}
            events={{ hover: true, focus: true, touch: true }}
            color="dark"
            radius="md"
            position="left"
            transition="fade"
            openDelay={250}
            transitionDuration={450}
            sx={{ fontSize: 11 }}
            withArrow>
            <ActionIcon
              color={getRoomOptions()?.audio ? 'red' : 'dark'}
              radius="xl"
              size={40}
              style={{
                backgroundColor: getRoomOptions()?.audio
                  ? 'rgba(235, 51, 35, 1)'
                  : 'rgba(255, 255, 255, 0.8)',
              }}
              className={classes.controls}
              onClick={handleMicAccess}>
              {getRoomOptions()?.audio ? (
                <MdMic size={25} color="white" stroke={1} />
              ) : (
                <MdMicOff size={25} stroke={1} />
              )}
            </ActionIcon>
          </Tooltip>
        </Box> */}

        <Box className={classes.muteButton}>
          <Tooltip
            label="Tap to Unmute"
            // opened={tooltipFirstMessage}
            opened={toggleMute}
            openDelay={1500}
            closeDelay={1000}
            transition="fade"
            transitionDuration={500}
            color="gray"
            radius="md"
            position="bottom-end"
            withArrow
            arrowOffset={10}
            arrowSize={10}
            opacity={0.5}
            style={{
              fontSize: 12,
              fontWeight: 500,
              color: 'black',
              backgroundColor: '#BCBCBC',
            }}>
            <ActionIcon
              sx={{ opacity: 0.7 }}
              variant="default"
              size={40}
              radius={50}
              className={classes.controls}
              onClick={() => {
                handleToggleMute();
                setTooltipFirstMessage(false);
              }}>
              {toggleMute ? (
                <MdVolumeOff size={25} stroke={1} />
              ) : (
                <MdVolumeUp size={25} stroke={1} />
              )}
            </ActionIcon>
          </Tooltip>
        </Box>

        <Box>
          <div className="participant">
            <Box>
              <div className={classes.gradient}>
                <Audio />
              </div>
            </Box>
          </div>
        </Box>
      </>
    );

  if (streamingFormat && streamingFormat === 'none')
    return (
      <div className="participant">
        <Center pt={10}>
          <Badge color="gray" size="sm" variant="filled">
            No Streaming Data
          </Badge>
        </Center>
      </div>
    );
};

export default Participant;

const useStyles = createStyles({
  container: {},
  participant: {
    overflow: 'hidden',
    backgroundColor: themeColors.backgound,
  },
  participantVideo: {
    objectFit: 'contain',
    backgroundColor: 'black',
    position: 'relative',
  },
  participantAudio: {
    position: 'relative',
  },

  gradient: {
    // display: 'flex',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    placeItems: 'center',
    margin: '0px',
    padding: '0px',
    listStyleType: 'none',
    opacity: 1,
    WebkitMaskImage:
      'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 25%, rgb(0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%)',
    overflow: 'hidden',
  },

  wavesurfer: {
    width: '100%',
  },

  position: {
    width: '100%',
    zIndex: 199,

    '@media (max-width: 1023px)': {
      position: 'absolute',
      top: 10,
    },

    '@media (min-width: 1024px)': {
      position: 'absolute',
      top: 25,
    },
  },

  statusVideo: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    paddingLeft: 10,
  },

  statusAudio: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    paddingLeft: 10,
    paddingTop: 0,
  },

  mic: {
    bottom: 0,
  },

  controls: {
    opacity: 0.8,
    transform: 'scale(1)',
    WebkitTransition: '250ms',

    '&:hover': {
      transform: 'scale(1.2)',
      opacity: 1,
      WebkitTransition: '250ms',
      boxShadow: '0px 7px 20px 0px rgba(0, 0, 0, 0.07)',
    },
  },

  playButton: {
    position: 'absolute',
    zIndex: 100,
    top: '0%',
    left: '0%',
    width: '100%',
    height: '100%',

    display: [
      '-webkit-box',
      '-webkit-flex',
      '-moz-box',
      '-moz-flex',
      '-ms-flexbox',
      'flex',
    ],
    WebkitBoxPack: 'center',
    msFlexPack: 'center',
    WebkitJustifyContent: 'center',
    MozJustifyContent: 'center',
    justifyContent: 'center',
    WebkitBoxAlign: 'center',
    msFlexAlign: 'center',
    WebkitAlignItems: 'center',
    MozAlignItems: 'center',
    alignItems: 'center',
  },

  controlsAudio: {
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
      WebkitTransition: '250ms',
      boxShadow: '0px 7px 20px 0px rgba(0, 0, 0, 0.07)',
    },
  },

  bg: {
    // backgroundImage: 'radial-gradient(circle at 50% 0, #3c3c3c, #141414 84%)',
  },

  micButton: {
    position: 'absolute',
    zIndex: 199,
    bottom: 20,
    right: 20,

    '@media (min-width: 1024px)': {
      bottom: 25,
      right: 30,
    },

    display: [
      '-webkit-box',
      '-webkit-flex',
      '-moz-box',
      '-moz-flex',
      '-ms-flexbox',
      'flex',
    ],
    WebkitBoxPack: 'center',
    msFlexPack: 'center',
    WebkitJustifyContent: 'center',
    MozJustifyContent: 'center',
    justifyContent: 'flex-end',
    WebkitBoxAlign: 'center',
    msFlexAlign: 'center',
    WebkitAlignItems: 'center',
    MozAlignItems: 'center',
    alignItems: 'flex-end',
  },

  muteButton: {
    position: 'absolute',
    zIndex: 200,

    display: [
      '-webkit-box',
      '-webkit-flex',
      '-moz-box',
      '-moz-flex',
      '-ms-flexbox',
      'flex',
    ],
    WebkitBoxPack: 'center',
    msFlexPack: 'center',
    WebkitJustifyContent: 'center',
    MozJustifyContent: 'center',
    justifyContent: 'flex-start',
    WebkitBoxAlign: 'center',
    msFlexAlign: 'center',
    WebkitAlignItems: 'center',
    MozAlignItems: 'center',
    alignItems: 'flex-start',

    top: 20,
    right: 20,

    '@media (min-width: 1024px)': {
      top: 25,
      right: 30,
    },
  },

  cameraSwitchButton: {
    position: 'absolute',
    zIndex: 201,

    display: [
      '-webkit-box',
      '-webkit-flex',
      '-moz-box',
      '-moz-flex',
      '-ms-flexbox',
      'flex',
    ],
    WebkitBoxPack: 'center',
    msFlexPack: 'center',
    WebkitJustifyContent: 'center',
    MozJustifyContent: 'center',
    justifyContent: 'flex-start',
    WebkitBoxAlign: 'center',
    msFlexAlign: 'center',
    WebkitAlignItems: 'center',
    MozAlignItems: 'center',
    alignItems: 'flex-start',

    bottom: 20,
    right: 20,

    '@media (min-width: 1024px)': {
      bottom: 25,
      right: 30,
    },
  },
});
