import React from 'react';
import { createStyles, Box, Stack } from '@mantine/core';

function ProfileSection({ children, ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      <Stack
        justify="flex-start"
        spacing={0}
        className={classes.profileSection}>
        {children}
      </Stack>
    </Box>
  );
}

export default ProfileSection;

const useStyles = createStyles({
  container: {},
  profileSection: {},
});
